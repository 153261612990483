import './App.css';
import './styles.css';
import React, { useEffect, useState, useContext } from 'react';
import { AppRouter } from './Router/AppRouter';
import { Layout } from './Router/LoginRouter';
//import { SideBarItems } from './Components/Common/Sidebar';
import Box from '@mui/material/Box';
import { getUser, getCurrentUserRole, setStoreData } from './Redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AbilityContext, updateAbility } from './Context/Can';

function App() {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [currentUserRoles, setCurrentUserRoles] = useState([]);
  const [clPermissions, setClPermissions] = useState([]);
  const [clientAdmin, setClientAdmin] = useState();
  const state = useSelector(st => st);
  const { getClientById: clientData } = state
  const client_id = clientData?.data?.data?.id

  const accessToken = localStorage.getItem("accessToken")

  const path = window.location.pathname;
  useEffect(() => {
    const allowedPaths = ['/login', '/forgot-password'];
    const regexPaths = [/\/reset-password\/.+/, /\/invite\/.+/];

    if (!accessToken && !allowedPaths.includes(path) && !regexPaths.some(regex => regex.test(path))) {
      window.location.href = '/login';
    }
    if(user) {
      dispatch(getCurrentUserRole()).then((userResp) => {
        setCurrentUserRoles(userResp?.data?.data)
      })
    }
    if(!path.includes('/projects') && localStorage.getItem('projectQueryParams')) {
      localStorage.removeItem('projectQueryParams');
    }
    if(!path.includes('/meters') && localStorage.getItem('meterQueryParams')) {
      localStorage.removeItem('meterQueryParams');
    }
    if(!path.includes('/users') && localStorage.getItem('customerUsersQueryParams')) {
      localStorage.removeItem('customerUsersQueryParams');
    }
    if(!path.includes('/tickets') && localStorage.getItem('ticketsQueryParams')) {
      localStorage.removeItem('ticketsQueryParams');
    }
    if(!path.includes('/digitalisation') && localStorage.getItem('digiTicketsQueryParams')) {
      localStorage.removeItem('digiTicketsQueryParams');
    }
    if(!path.includes('/digitalisation') && ( localStorage.getItem('calibrationQueryParams') || localStorage.getItem('siteVisitQueryParams') )) {
      localStorage.removeItem('calibrationQueryParams');
      localStorage.removeItem('siteVisitQueryParams');
    }
    if(!path.includes('/client-tickets') && localStorage.getItem('clientTicketsQueryParams')) {
      localStorage.removeItem('clientTicketsQueryParams');
    }
    if(!path.includes('/client-assets') && localStorage.getItem('clientAssetQueryParams')) {
      localStorage.removeItem('clientAssetQueryParams');
    }
    if(!path.includes('/maintenance') && localStorage.getItem('maintenanceLogQueryParams')) {
      localStorage.removeItem('maintenanceLogQueryParams');
    }
    if(!path.includes('/components') && localStorage.getItem('componentsQueryParams')) {
      localStorage.removeItem('componentsQueryParams');
    }

  }, [accessToken, path])

  useEffect(() => {
    if (clientData?.data?.data) {
      const clientPermission = clientData?.data?.data?.permission;
      if (clientPermission) {
        const trueValues = Object.keys(clientPermission).filter(key => clientPermission[key]).map(key => key.charAt(0).toUpperCase() + key.slice(1));
        setClPermissions(trueValues);
      }
    }
    if(client_id && currentUserRoles?.length > 0) {
      const cAdmin = currentUserRoles.find(item => {
        return item?.permission?.some(userPermission => {
          return userPermission?.type === 'client' && userPermission?.permissions?.edit;
        });
      });
    
      if (cAdmin) {
        setClientAdmin(cAdmin);
      }
    }
  }, [clientData, client_id])

  useEffect(() => {
    dispatch(getUser()).then((resp) => {
      console.log('setting current user', resp);
      const res = resp && resp.data;
      if (res && res.success && res.user) {
        dispatch(setStoreData('currentUser', res.user))
        dispatch(getCurrentUserRole()).then((userResp) => {
          console.log(userResp);
          setCurrentUserRoles(userResp?.data?.data)
        })
        setUser(res.user);
      } else {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken && !res?.success) {
          localStorage.removeItem('accessToken');
          window.location.href = '/login'
        }
      }
    });
  }, [1]);
  let userInvite = false;

  if (path.startsWith('/invite')) {
    userInvite = true
  }
  const ability = useContext(AbilityContext);
  updateAbility(currentUserRoles, ability, client_id, clPermissions, clientAdmin);

  return (
    <Box sx={{ display: 'flex' }}>
      {(user) ? <AppRouter user={user} currentUserRoles={currentUserRoles} /> : <Layout />}
    </Box>
  )
}

export default App;