export const removeNonValueFilter = (filterValues) => {
    const filters = {}
    Object.keys(filterValues).forEach(key => {
        if (filterValues[key]) {
            Object.assign(filters, { [key]: filterValues[key] })
        }
    })
    return filters
}

export const random = (max) => {
    return Math.floor(Math.random() * max)
}

export const makeid = (length, prefix, shard = 'AA', seprator = '-') => {
    let result = `${shard}${prefix}`;
    const characters = 'abcdefghijklmnopqrstuvwxyz';
    const charLen = characters.length;
    for (let i = 0; i < length; i++) {
        if (i % 4 === 0) {
            result += seprator;
        }
        result += characters.charAt(random(charLen));

    }
    return result.toLowerCase();
}

const indiaTimeZone = 'Asia/Kolkata';
const qatarTimeZone = 'Asia/Qatar';

export const getToday = () => {
    const now = new Date();
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return now.toLocaleDateString('en-IN', options);
};

export const getQatarToday = () => {
    const now = new Date();
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return now.toLocaleDateString('en-QA', options);
};

export const getPriorDate = (nprev_days) => {
    var today = new Date();
    var priorDate = new Date(new Date().setDate(today.getDate() - nprev_days));
    return priorDate
  }

export const restrictFutureDates = () =>  {
    var yesterday = getPriorDate(1)
    // minimum date the user can choose, in this case now and in the future
    const maxDate = yesterday.toISOString().substring(0, 10);
    return maxDate
  }

export const meterGroupDisplayOrder = [
    'water_quantity', 'water_quality', 'energy', 'equipment_status', 'pressure', 'level', 'air_quality', 'temperature', 'auto_chlorination'
]

export const generateInsightKey = (...args ) => {
const key = args.join('-')
return `g-${key}`
}  

export const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const calculateDueDays = (operationStartDate, schedule, assetMaintenance, operationEndDate) => {
    const operationStartDateObj = new Date(operationStartDate);
    const operationEndDateObj = new Date(operationEndDate)
    const today = new Date();
    const daysSinceOperationStart = Math.ceil((today - operationStartDateObj) / (1000 * 60 * 60 * 24));
    const daysTillOperationEndDate = (operationEndDate !== null) ? Math.ceil((operationEndDateObj - operationStartDateObj) / (1000 * 60 * 60 * 24)) : null

    schedule.sort((a, b) => a.offset_time_days - b.offset_time_days);

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    for (const item of schedule) {

        const actualMaintenanceDate = new Date(operationStartDate);
        actualMaintenanceDate.setDate((operationStartDateObj.getDate() + item.offset_time_days) - 1);

        const daysDifference = daysSinceOperationStart - item.offset_time_days; 
        const isMaintenanceValid = daysTillOperationEndDate - item.offset_time_days

        if ((operationEndDate !== null) &&  (isMaintenanceValid >= 0 ||  operationEndDateObj <= today) ) {
            return {
                dueDays: null,
                scheduleItem: item,
                overdueDays: null,
                actualMaintenanceDate: null,
                maintenanceCompleted: true
            }
        }

        const maintenanceItem = assetMaintenance.find(
            (am) => am.maintenance_schedule_id === item.id
        );

        if (daysDifference < 0) {
            return {
                dueDays: Math.abs(daysDifference),
                overdueDays: null,
                scheduleItem: item,
                actualMaintenanceDate: formatDate(actualMaintenanceDate)
            };
        }

        if (daysDifference >= 0) {
            if (!maintenanceItem || maintenanceItem.status !== "Completed") {
                return {
                    dueDays: null,
                    overdueDays: daysDifference,
                    scheduleItem: item,
                    actualMaintenanceDate: formatDate(actualMaintenanceDate)
                };
            }
        }
    }

    return { dueDays: null, scheduleItem: null, overdueDays: null, maintenanceCompleted: true };
};



export const calculateOverDue = (completionDate, operationStartDate, schedule) => {
    const operationStartDateObj = new Date(operationStartDate);
    const completionDateObj = new Date(completionDate)
    const today = new Date();

    const actualMaintenanceDate = new Date(operationStartDate);
    actualMaintenanceDate.setDate((operationStartDateObj.getDate() + schedule) - 1);

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    if ( completionDate ){
        const totlCompletionDays = Math.ceil((completionDateObj - operationStartDateObj) / (1000 * 60 * 60 * 24));
        const overDue = (totlCompletionDays - schedule)+1

        return {
            overDue,
            actualMaintenanceDate: formatDate(actualMaintenanceDate)
        }
    }
    const totlCompletionDays = Math.ceil((today - operationStartDateObj) / (1000 * 60 * 60 * 24));
    const overDue = (totlCompletionDays - schedule)
    return {
        overDue,
        actualMaintenanceDate: formatDate(actualMaintenanceDate)
    };
    
}

export const STP_WATER_BALANCE_TAGS = ['STP Source', 'STP Treatment', 'STP Flushing Consumption', 'STP Garden Consumption', 'STP Cooling Tower Consumption']
export const STP_ENERGY_TAGS = ['STP Energy', 'STP Treatment']
export const STP_OPEX_COST_TAGS = ['STP Energy', 'STP Treatment']
export const STP_WQPI_TAGS = ['STP - pH', 'STP - TDS', 'STP - ORP', 'STP - TBD', 'STP - TSS', 'STP - BOD', 'STP - COD']

export const WTP_WATER_BALANCE_TAGS = ['WTP Treatment', 'WTP Source', 'WTP Domestic Consumption', 'WTP Garden Consumption', 'WTP Cooling Tower Makeup']
export const WTP_ENERGY_TAGS = ['WTP Energy', 'WTP Treatment']
export const WTP_WQPI_TAGS = ['WTP pH','WTP TDS', 'WTP ORP']

export const ETP_WATER_BALANCE_TAGS = ['ETP Treatment', 'ETP Source', 'ETP Garden Consumption', 'ETP Flushing Consumption']
export const ETP_ENERGY_TAGS = ['ETP Energy', 'ETP Treatment']
export const ETP_OPEX_COST_TAGS = ['ETP Energy', 'ETP Treatment']
export const PROJECT_TYPE_VALUES = ['Live', 'Demo/ Test', 'Water Balance', 'Not Applicable'];
export const COMPONENTS_CATEGORIES = [
    'sewage transfer pump1',
    'sewage transfer pump2',
    'Air blower 1',
    'Air blower 2',
    'Sludge Transfer Pump 1',
    'Sludge transfer pump2',
    'Filter feed pump 1',
    'Filter feed Pump2',
    'Garden Pump 1',
    'Garden Pump2',
    'Flushing Pump1',
    'Flushing Pump2',
    'Dosing Pump',
    'Fresh Air Fan1',
    'MBR Feed Pump1',
    'MBR Feed Pump2',
    'Screw Pump1',
    'Screw Pump2',
    'Cold water feed pump1',
    'Cold water feed pump2',
    'Hot water Recirculation pmp1',
    'Hot water Recirculation pmp2',
    'Boiler water feed pump2',
    'Boiler water feed pump2',
    'Domestic water pump1',
    'Domestic water pump2'
]

export const updateParams = (existingParams, params) => {
    const combinedParams = Object.assign({}, existingParams, params);
    const filteredParams = removeNonValueFilter(combinedParams);

    const searchParams = new URLSearchParams();
    if (Object.keys(filteredParams).length > 0) {
        Object.keys(filteredParams).forEach(key => searchParams.set(key, filteredParams[key]));
    }
    return searchParams
}