import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import $ from 'jquery';
import { useDispatch } from "react-redux";
import { saveConsolidateReport, setStoreData, updateConsolidateReport } from "../../Redux/actions";

export default function ReportsContent({ htmlDataContent, setHtmlDataContent, editReport, reportFromDate, reportToDate, client_id, projectId, reportType,
    onReportSaved, projectName, currentReportId, tableRef
}) {
    const dispatch = useDispatch();
    const [contentEditable, setContentEditable] = useState(false)
    const [isSaved, setIsSaved] = useState(true);

    useEffect(() => {
        if (reportType && isSaved[projectId]) {
            setContentEditable(false)
            setIsSaved(true)
        }
    }, [reportType])

    useEffect(() => {
        setContentEditable(editReport);
        setIsSaved(false)
    }, [editReport])

    // useEffect(() => {
    //     if (htmlDataContent?.length > 0) {
    //         setHtmlContent(htmlDataContent)
    //     }
    // }, [htmlDataContent])

    useEffect(() => {
        if (reportType === 'Day-wise') {
            limitViolationColors();
            highlight();
        }
    }, [htmlDataContent])


    const limitViolationColors = () => {
        var acceptable_limits = {}
        $('#quality tbody tr:first-child .tg-lboi').each(function (i, d) {
            acceptable_limits[i] = d.innerHTML
        })
        const acceptableLimitsString = JSON.stringify(acceptable_limits, null, 2);
        var test = Object.values(acceptable_limits)
        var quality_row_count = $('#quality tbody tr').length;
        for (let k = 2; k <= quality_row_count; k++) {
            $('#quality tbody tr:nth-child(' + k + ') .tg-lboi').each(function (i, d) {
                var limit = test[i].split("-").map(v => v.trim())
                if (limit[0] != '' && limit[1] != '') {
                    if (parseFloat(limit[0]) <= parseFloat(d.innerHTML) && parseFloat(d.innerHTML) <= parseFloat(limit[1])) {
                        $(this).addClass("green")
                    }
                    else {

                        $(this).addClass("red")
                    }
                }

                else if (limit[0] != '' && limit[1] == '') {
                    if (parseFloat(limit[0]) <= parseFloat(d.innerHTML)) {
                        $(this).addClass("green")
                    }
                    else {

                        $(this).addClass("red")
                    }
                }

                else if (limit[0] == '' && limit[1] != '') {
                    if (parseFloat(d.innerHTML) <= parseFloat(limit[1])) {
                        $(this).addClass("green")
                    }
                    else {

                        $(this).addClass("red")
                    }
                }
                else if (limit[0] == '' && limit[1] == '') {
                    $(this).addClass("green")
                }
                if (d.innerHTML == 'NA') {
                    $(this).addClass("normal")
                }

            })
        }
    }
    const highlight = () => {
        var quantity_row_count = $('#quantity tr').length;
        $('#quantity tr:nth-child(2) .tg-cqae').each(function (i, d) {
            var column_name = d.innerHTML.toLowerCase()
            if (column_name.startsWith('total')) {
                for (let j = 1; j <= quantity_row_count - 2; j++) {
                    $('#quantity tr:nth-child(' + j + ') .tg-lboi').each(function (k, d) {
                        if (k == i - 1) {
                            $(this).addClass("highlight")
                        }
                    })
                }
            }
        })
    }

    const updateResponse = (resp, message) => {
        const res = resp && resp.data;
        let msg = res?.message;
        if (msg) {
            let type = "fail";
            if (res && res.success) {
                msg = message;
                type = "success";
            }
            dispatch(setStoreData('alertMessage', {
                msg, type,
                handleClose: () => handleCloseDialog(),
            }))
        }
    };

    const handleCloseDialog = () => {
        dispatch(setStoreData('alertMessage', null));
    }

    const saveEditedReportData = (pid) => {
        setIsSaved(prev => ({ ...prev, [pid]: true }));
        let quality = {};
        let quantity = {};
        let energy_data = {};
        let air_quality_data = {};
        let equipment_data = {};
        let pressure_data = {};
        let temperature_data = {};
        let auto_chlorination_data = {};

        const extractData = (tableId, dataObject) => {
            const row_count = $(tableId + ' tr').length;
            for (let i = 2; i <= row_count; i++) {
                const meterName = $(tableId + ' tr:nth-child(' + i + ') .td-metername-' + pid).text().trim();
                let numericValues = [];
                if (meterName) {
                    $(tableId + ' tr:nth-child(' + i + ') .td-numeric-' + pid).each(function () {
                        const value = $(this).text().trim();
                        numericValues.push(value);
                    });
                    dataObject[`${meterName}_#${i - 2}`] = { order: i - 2, values: numericValues };
                }
            }
        };

        extractData('#quality', quality);
        extractData('#quantity', quantity);
        extractData('#energy_data', energy_data);
        extractData('#air_quality_data', air_quality_data);
        extractData('#equipment_data', equipment_data);
        extractData('#pressure_data', pressure_data);
        extractData('#temperature_data', temperature_data);
        extractData('#auto_chlorination_data', auto_chlorination_data);

        const comment = $('#td-comment-' + pid).map(function(i,d){return $('<textarea/>').html(d.innerHTML).text()})[0]
        const comment_text = comment?.replace(/<(?!br\s*\/?)[^>]+>/g, '').replace(/<br[^>]*>/g, '<br>');
        const header = $('#report-header').text().trim();
        const poweredByIndex = header.indexOf("Powered by");
        const header_text = header.substring(0, poweredByIndex)

        const reportData = {
            quality,
            quantity,
            equipment_status: equipment_data,
            air_quality_data,
            energy: energy_data,
            pressure_data,
            temperature_data,
            auto_chlorination_data,
            comments: comment_text,
            from_date: reportFromDate || null,
            to_date: reportToDate || null,
            project_name: projectName,
            pid,
            client_id,
            header: header_text
        };
        
        if (currentReportId) {
            dispatch(updateConsolidateReport(currentReportId, reportData)).then(resp => {
                if (resp?.data?.success) {
                    onReportSaved(pid)
                    updateResponse(resp, resp?.data?.message);
                    setHtmlDataContent("");
                }
            });
            return
        }

        dispatch(saveConsolidateReport(reportData)).then(resp => {
            if (!resp?.data?.success) {
                return
            }
            // setContentEditable((prev) => ({ ...prev, [pid]: false }));
            // setContentEditable(false);
            onReportSaved(pid)
            updateResponse(resp, resp?.data?.message);
        });
    };

    return <>
        {htmlDataContent && (
            <>
                <div ref={tableRef} className='graph-container' dangerouslySetInnerHTML={{ __html: htmlDataContent }} />
                {contentEditable && !isSaved && <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        size='small'
                        color='primary'
                        style={{ padding: 7, backgroundColor: 'white' }}
                        variant="outlined"
                        onClick={() => {
                            saveEditedReportData(projectId);
                        }}
                        className="hide-in-print"
                    >
                        Save Data
                    </Button>
                </div>
                }
            </>
        )}
    </>
}