import { TextField, FormControl, InputLabel, ListItemText, Autocomplete, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createNewMeter, deleteMeter, getAllMeterTags, getAllMeterStatus, setStoreData } from '../../Redux/actions';
import { useLocation, useParams } from 'react-router-dom';
import { getMeterById, updateMeter, getAllProject, getClientById } from '../../Redux/actions';
import Paper from '@mui/material/Paper';
import ConfirmDialog from '../Common/ConfirmDialog';
import Loader from '../Common/Loader';
import { Can } from '../../Context/Can';
import { DeleteIcon } from '../Common/TableUI';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {isValidDate} from '../../utils/Common';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function ManageMeters() {

    const location = useLocation();
    const locationState = location?.state;

    let navigate = useNavigate()

    const meterQueryParams = localStorage.getItem("meterQueryParams");

    let initialForm = {
        meterName: "",
        meter_id: "",
        meterAddress: "",
        parameterGrp: "",
        hardcodedMtrName: "",
        min: "",
        minRemarks: "",
        selectedMeterTags:[],
        selectedProjectIds:[],
        max: "",
        maxRemarks: "",
        waterType: "",
        alertPriority: "",
        hideChart: "",
        percLimit: "",
        tankArea: "",
        tankHeight: "",
        location: "",
        facility: "",
        project: "",
        status: ""
    }

    const parameterGroup = [
        { label: 'Water Quality', value: 'water_quality' },
        { label: 'Water Quantity', value: 'water_quantity' },
        { label: 'Energy', value: 'energy' },
        { label: 'Pressure', value: 'pressure' },
        { label: 'Temperature', value: 'temperature' },
        { label: 'Level', value: 'level' },
        { label: 'Equipment Status', value: 'equipment_status' },
        { label: 'Air Quality', value: 'air_quality' },
        { label: 'Auto Chlorination', value: 'auto_chlorination' },
    ];

    const waterTypeOptions = [
        { label: 'Fresh Water', value: 'fresh_water' },
        { label: 'Recycled Water', value: 'recycled_water' },
        { label: 'Not Applicable', value: 'not_applicable' },
    ]

    const hardCodedMtrNameList = ['Flow Rate', 'FL - Inlet Flow', 'FL - Total Flow', 'Pressure', 'Not Applicable']
    const [form, setForm] = useState(initialForm);

    const [showWaterType, setShowWaterType] = useState(false);
    const [showTankFields, setShowTankFields] = useState(false);
    // const [locationData, setLocationData] = useState(null)
    // const [faciltyData, setFacilityData] = useState(null)
    const [projectData, setProjectData] = useState([])
    const dispatch = useDispatch();
    let { id: id, client_id: client_id } = useParams()
    const error = {};
    const [errors, setErrors] = useState(error);
    const [meterTagErrors, setMeterTagErrors] = useState({});
    const [selectedMeterTags, setSelectedMeterTags] = useState([])
    const [selectedProjectIds, setSelectedProjectIds] = useState([])
    const [disableButton, setDisableButton] = useState(false);
    const [tagDisableButton, setTagDisableButton] = useState(false);
    const [meterTags, setMeterTags] = useState([])
    const [meterStatus, setMeterStatus] = useState([])
    const [selectedItem, setSelectedItem] = useState(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [openAddTagDialog, setOpenAddTagDialog] = useState(false);
    const [customTagKey, setCustomTagKey] = useState('');
    const [customTagText, setCustomTagText] = useState('');

    const [installation_date, setInstallationDate] = useState(null)
    const [selectedSmallestDate, setSelectedSmallestDate] = useState(null)
    const [meterCommissionDate, setMeterCommissionDate] = useState(null)

    const [itemNotFound, setItemNotFound] = useState(false);
    const [showLoader, setShowLoader] = useState( true );
    const [constantValueFlag, setConstantValueFlag] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {    
                // Example: Uncomment and handle other dispatch calls as needed
                // const clientLocations = await dispatch(getClientLocations(client_id));
                // if (clientLocations?.status === 200) {
                //     const respData = clientLocations.data;
                //     if (respData) setLocationData(respData.data);
                // }
    
                // const facilities = await dispatch(getAllFacilities(client_id));
                // if (facilities?.status === 200) {
                //     setFacilityData(facilities.data.data);
                // }
    
                const client = await dispatch(getClientById(client_id));
                if (!client?.data?.data) {
                    setItemNotFound(true);
                    return;
                }
    
                const projects = await dispatch(getAllProject(client_id, false));
                if (projects?.status === 200) {
                    setProjectData(projects.data.allData);
                }
    
                const meterTags = await dispatch(getAllMeterTags());
                if (meterTags?.status === 200) {
                    setMeterTags(meterTags.data.meterTags);
                }
    
                const meterStatus = await dispatch(getAllMeterStatus());
                if (meterStatus?.status === 200) {
                    setMeterStatus(meterStatus.data.meterStatus);
                }
    
                if (id) {
                    const meter = await dispatch(getMeterById(client_id, id));
                    const tagResponse = meter?.data?.meterTagData;
                    const response = meter?.data?.meterData;
    
                    if (!response) {
                        setItemNotFound(true);
                        return;
                    }
    
                    const {
                        metername, meterid, meter_address, meter_group, hard_coded_metername,
                        min, minremarks, max, maxremarks, water_type, alert_priority, hide_chart,
                        perc_change, tank_area, tank_height, location_id, facility_id, project_id,
                        projectMeter, commission_tm, constant_value_flag, constant_value, status
                    } = response;
    
                    if (commission_tm) {
                        const commissionDate = new Date(commission_tm);
                        const year = commissionDate.getFullYear();
                        const month = commissionDate.getMonth() + 1;
                        const day = commissionDate.getDate();
    
                        const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
                        setInstallationDate(formattedDate);
                        setMeterCommissionDate(formattedDate);
                    }
    
                    if (constant_value_flag) {
                        setConstantValueFlag(constant_value_flag === 'true');
                    }
    
                    setSelectedMeterTags(tagResponse.map(item => ({ key: item.tag_key, text: item.tag_text })));
                    setSelectedProjectIds(projectMeter.map(item => item?.project));
    
                    const existData = {
                        meterName: metername,
                        meter_id: meterid,
                        meterAddress: meter_address,
                        parameterGrp: meter_group,
                        hardcodedMtrName: hard_coded_metername,
                        min,
                        minRemarks: minremarks,
                        max,
                        maxRemarks: maxremarks,
                        waterType: water_type,
                        alertPriority: alert_priority,
                        hideChart: hide_chart === "true",
                        percLimit: perc_change,
                        tankArea: tank_area,
                        tankHeight: tank_height,
                        location: location_id,
                        facility: facility_id,
                        client_id,
                        status,
                        constantValue: constant_value || constant_value === 0 ? `${constant_value}` : ''
                    };
    
                    setForm(existData);
                    if (existData.parameterGrp === 'water_quantity') {
                        setShowWaterType(true);
                    } else if (existData.parameterGrp === 'level') {
                        setShowTankFields(true);
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setShowLoader(false);
            }
        };
    
        fetchData();
    }, [client_id, id, dispatch]);
    

    useEffect(() => {
        if (selectedProjectIds?.length > 0) {
            const selectedSmallestDate = calculateSmallestDate(selectedProjectIds);
            setSelectedSmallestDate(selectedSmallestDate);
        }
    }, [selectedProjectIds]);

    useEffect(() => {
        if(selectedSmallestDate && !id) {
            setInstallationDate(selectedSmallestDate)
        }
    
    }, [selectedSmallestDate, id]);
    
    const calculateSmallestDate = (selectedProjects) => {
        if (!installation_date) {
            const startDates = selectedProjects.map(project => project.start_date);
            const validDates = startDates.filter(date => date !== "");
            if (startDates.length > 0) {

                const smallestDate = validDates.reduce((minDate, currentDate) => {
                    return currentDate < minDate ? currentDate : minDate;
                }, validDates[0]);

                setInstallationDate(smallestDate)
                return smallestDate
            } else {
                setInstallationDate(null)
                return null;
            }
        }
    }


    const leastDateCheck = (selectedProject) => {
        if(!meterCommissionDate) {
            const newSelectedDate = calculateSmallestDate(selectedProject);
            setInstallationDate(newSelectedDate);
        }
    }
    const handleChange = (e, fieldName) => {
        const { value } = e.target;
        if ( fieldName === 'meter_id' ) {
            const isValid = /^[0-9]*$/.test(value);
            if (!isValid) {
                return;
            }
        }
        const data = (prevState) => {
            let newData = {
                ...prevState,
                [fieldName]: value
            };
            if (fieldName === 'parameterGrp') {
                newData.constantValue = form?.constantValue ? form?.constantValue : 0;
            }
            return newData;
        };
        const err = Object.assign({}, errors);
        if (err[fieldName]) {
            err[fieldName] = null;
        }
        
        if (fieldName === 'min' && (parseFloat(value) < parseFloat(form.max))) {
            err.min = null;
            err.max = null;
        } if (fieldName === 'max' && (parseFloat(value) > parseFloat(form.min))) {
            err.min = null;
            err.max = null;
        }

        if (fieldName === 'parameterGrp') {
            setShowWaterType(value === 'water_quantity');
            setShowTankFields(value === 'level');
            err.min = null;
            err.max = null;
            err.minRemarks = null;
            err.maxRemarks = null;
            err.percLimit = null;
        }

        setErrors(err);
        setForm(data);
        if(disableButton){
            setDisableButton(false)
        }
    };

    const handleAutoCompleteChange = (event,value) => { 
        const filteredValue = value.filter((item) => item.value !== 'custom');
        setSelectedMeterTags(filteredValue);
        
    }

    const handleProjectAutoCompleteChange = (event,value) => { 
        const err = Object.assign({}, errors);
        if (err['project']) {
            err['project'] = null;
        }
        if (err['installation_date']) {
            err['installation_date'] = null;
        }
        setErrors(err)
        setSelectedProjectIds(value);
        leastDateCheck(value);
        if(disableButton){
            setDisableButton(false)
        }
    }

    const handleOpenAddTagDialog = () => {
        setOpenAddTagDialog(true);
        setTagDisableButton(false)
    };

    const handleCloseAddTagDialog = () => {
        setOpenAddTagDialog(false);
        setCustomTagKey('');
        setCustomTagText('');
        setMeterTagErrors({})
    };

    const validateCustomMeterTag = () => {
        const err = { ...meterTagErrors };
        let hasErrors = false;

        if(meterTags.some(tag => tag.key.toLowerCase() === customTagKey.toLowerCase())) {
            hasErrors = true
            err.customTagKey = 'This key already exist'
        }
        if(meterTags.some(tag => tag.text.toLowerCase() === customTagText.toLowerCase())) {
            hasErrors = true
            err.customTagText = 'This value already exist'
        }

        if(customTagKey === '' || customTagKey.trim() === '' ){
            hasErrors = true
            err.customTagKey = 'This field is mandatory'
        }
        if(customTagText === '' || customTagText.trim() === '' ){
            hasErrors = true
            err.customTagText = 'This field is mandatory'
        }

        if(selectedMeterTags.map((tag) => tag.text).includes(customTagText)){
            hasErrors = true
            err.customTagText = 'This value already exist'
        }
        if(selectedMeterTags.map((tag) => tag.key).includes(customTagKey)){
            hasErrors = true
            err.customTagKey = 'This key already exist'
        }

        if (hasErrors) {
            setMeterTagErrors(err);
            return false;
        }
        return true;
    }

    const handleAddCustomTag = () => {
        setTagDisableButton(true)
        const newCustomTag = { key: customTagKey, text: customTagText };
        const checkCustomMtrTagForm = validateCustomMeterTag();
        if(checkCustomMtrTagForm){
            setSelectedMeterTags((prevTags) => [...prevTags, newCustomTag]);
            handleCloseAddTagDialog();
        }
      };

    const handleCustomMtrTag = (key) => {
        if (meterTagErrors && meterTagErrors[key]) {
            meterTagErrors[key] = '';
            setTagDisableButton(false)
            setMeterTagErrors(meterTagErrors)
        }
    }  

    const renderOption = (props, option, { selected }) => {
        return (
          <li {...props}>
            <span>{option.text}</span>
            {option.value === 'custom' && (
              <Button fullWidth onClick={handleOpenAddTagDialog}>+ Add Meter Tag</Button>
            )}
          </li>
        );
    };

    const handleHideChart = (e) => {
        const { checked } = e.target;
        const data = (prevState) => ({
            ...prevState,
            hideChart: checked
        })
        setForm(data);
        if(disableButton){
            setDisableButton(false)
        }
        
    }

    const validateData = () => {
        const err = { ...errors };

        let hasErrors = false;
        if (!form.meterName || form.meterName.trim() === '') {
            hasErrors = true;
            err.meterName = 'This field is mandatory';
        }

        if (!form.meter_id || form.meter_id.trim() === '') {
            hasErrors = true;
            err.meter_id = 'This field is mandatory';
        }

        if (!form.meterAddress || form.meterAddress.trim() === '') {
            hasErrors = true;
            err.meterAddress = 'This field is mandatory';
        }

        if (!form.parameterGrp) {
            hasErrors = true;
            err.parameterGrp = 'This field is mandatory';
        }

        if (!form.status) {
            hasErrors = true;
            err.status = 'This field is mandatory';
        }

        if (form.parameterGrp === 'water_quantity') {
            if (!form.waterType) {
                hasErrors = true;
                err.waterType = 'This field is mandatory';
            }
        }

        if (form.parameterGrp === 'level') {
            if (!form.tankArea) {
                hasErrors = true;
                err.tankArea = 'This field is mandatory';
            }
            else if (!/^\d+(\.\d+)?$/.test(form.tankArea)) {
                hasErrors = true;
                err.tankArea = 'Please enter a valid digit';
            }
            if (!form.tankHeight) {
                hasErrors = true;
                err.tankHeight = 'This field is mandatory';
            }
            else if (!/^\d+(\.\d+)?$/.test(form.tankHeight)) {
                hasErrors = true;
                err.tankHeight = 'Please enter a valid digit';
            }
        }

        // if (!form.hardcodedMtrName) {
        //     hasErrors = true;
        //     err.hardcodedMtrName = 'This field is mandatory';
        // }

        if ( form.parameterGrp === 'level'){
            if (!form.min) {
                hasErrors = true;
                err.min = 'This field is mandatory';
            }
            if (!form.minRemarks || form.minRemarks.trim() === '') {
                hasErrors = true;
                err.minRemarks = 'This field is mandatory';
            }
            if (!form.max) {
                hasErrors = true;
                err.max = 'This field is mandatory';
            } 
            if (!form.maxRemarks || form.maxRemarks.trim() === '') {
                hasErrors = true;
                err.maxRemarks = 'This field is mandatory';
            }
            if (form.percLimit === null || form.percLimit === undefined || form.percLimit === "") {
                hasErrors = true;
                err.percLimit = 'This field is mandatory';
            }
        }
        if (form.min !== '' && !/^\d+(\.\d+)?$/.test(form.min)) {
            hasErrors = true;
            err.min = 'Please enter a valid digit';
        } else if (form?.min?.length > 10) {
            hasErrors = true;
            err.min = 'Maximum 10 characters allowed';
        }
        if (form.max !== '' && !/^\d+(\.\d+)?$/.test(form.max)) {
            hasErrors = true;
            err.max = 'Please enter a valid digit';
        } else if (form?.max?.length > 10) {
            hasErrors = true;
            err.max = 'Maximum 10 characters allowed';
        }
        if (parseFloat(form.min) > parseFloat(form.max)) {
            hasErrors = true;
            err.min = 'Min value cannot be greater than Max value';
            err.max = 'Max value cannot be smaller than Min value';
        }
        if (form.percLimit !== '' && ( isNaN(parseFloat(form.percLimit)) || parseFloat(form.percLimit) < 0 || parseFloat(form.percLimit) > 100)) {
            hasErrors = true;
            err.percLimit = 'Please enter a valid percentage between 0 and 100';
        }
        if(form?.parameterGrp === 'level' && constantValueFlag && form?.constantValue?.trim() === '') {
            hasErrors = true;
            err.constantValue = 'This field is mandatory'
        }

        if(form?.parameterGrp === 'level' && constantValueFlag && !/^-?\d+(\.\d+)?$/.test(form?.constantValue.trim())) {
            hasErrors = true;
            err.constantValue ='Please enter a valid digit';
        }
        
        if (!form.alertPriority || form.alertPriority === 'null') {
            hasErrors = true;
            err.alertPriority = 'This field is mandatory';
        }
        // if (!form.location) {
        //     hasErrors = true;
        //     err.location = 'This field is mandatory';
        // }
        // if (!form.facility) {
        //     hasErrors = true;
        //     err.facility = 'This field is mandatory';
        // }
        if (selectedProjectIds?.length <= 0) {
            hasErrors = true;
            err.project = 'This field is mandatory';
        } else {
            const selectedFacilities = new Set(selectedProjectIds.map(item => item?.facilities?.facility_name));
            if (selectedFacilities?.size > 1) {
                hasErrors = true;
                err.project = 'Select projects belong to the same facility';
            }
        }
       

        if (!installation_date) {
            hasErrors = true;
            err.installation_date = 'This field is mandatory';
        } else if (!isValidDate(installation_date)) {
            hasErrors = true;
            err.installation_date = 'Please enter a valid date in the format DD/MM/YYYY';
        }

        if (hasErrors) {
            setErrors(err);
            return false;
        }
        return true;
    };
    const handleSubmit = () => {
        setDisableButton(true)
        const checkForm = validateData();
        if (checkForm) {
            const selectedPids = selectedProjectIds.map(project => project.pid);
            let hardcodedMtrName = form.meterName
            if ( hardCodedMtrNameList.includes(form.hardcodedMtrName) || form.hardcodedMtrName === form.meterName ){
                hardcodedMtrName = form.hardcodedMtrName
            }
            const data = {
                meterName: form.meterName,
                meter_id: form.meter_id,
                meterAddress: form.meterAddress,
                parameterGrp: form.parameterGrp,
                hardcodedMtrName,
                min: form.min,
                minRemarks: form.minRemarks,
                max: form.max,
                maxRemarks: form.maxRemarks,
                waterType: form.waterType,
                alertPriority: form.alertPriority,
                hideChart: form.hideChart,
                percLimit: form.percLimit,
                tankArea: form.tankArea,
                tankHeight: form.tankHeight,
                client_id,
                location: form.location,
                meterTag:selectedMeterTags,
                facility: form.facility,
                project: selectedPids,
                installation_date,
                constantValueFlag,
                constantValue: form.constantValue,
                status: form.status
            }

            
            if (id) {
                dispatch(updateMeter(data, id)).then((resp) => {
                    setDisableButton(false)
                    if (!resp) {
                        return
                    } else {
                        updateResponse(resp, resp?.data?.message);
                    }
                })
            } else {
                dispatch(createNewMeter(data)).then(resp => {
                    setDisableButton(false)
                    if (!resp) {
                        return
                    } else {
                        updateResponse(resp, resp?.data?.message);
                    }
                })
            }
        }
    }

    const updateResponse = (resp, message) => {
        const res = resp && resp.data;
        let msg = res?.message;
        if (msg) {
            let type = "fail";
            if (res && res.success) {
                msg = message;
                type = "success";
            }
            dispatch(setStoreData('alertMessage', {
                msg, type,
                handleClose: () => handleCloseDialog(resp),
            }))
        }

    };

    const handleCloseDialog = (resp) => {
        dispatch(setStoreData('alertMessage', null));
        (meterQueryParams && id) ? navigate(`/client/${client_id}/meters?${meterQueryParams?.toString()}`) : navigate(`/client/${client_id}/meters`)
    }

    const triggerDelete = () => {
        setSelectedItem(id);
        setShowConfirmDialog(true);
    }

    const handleDeleteMeter = () => {
        if (selectedItem) {
            setShowConfirmDialog(false);
            setDeleteLoader(true)
            dispatch(deleteMeter(client_id, id)).then((resp) => {
                setDeleteLoader(false)
                updateResponse(resp, resp?.data?.message);
                setSelectedItem(null);
            })
        }
    }

    if (id && itemNotFound) {
        return <Grid container >
            <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center', height: '500px',  alignItems: 'center' }}>
                <h5 className='w3-text-grey'>
                    No Data Found
                </h5>
            </Grid>
        </Grid>

    } else if (!id && itemNotFound) {
        return <Grid container >
            <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center', height: '500px',  alignItems: 'center' }}>
                <h5 className='w3-text-grey'>
                    Create meter not available due to invalid client id.
                </h5>
            </Grid>
        </Grid>
    }

    if (showLoader) {
        return <Grid container>
            <Grid style={{display: 'flex', justifyContent: 'center', width: '100vw', height: '500px',  alignItems: 'center' }}>
                <CircularProgress />
            </Grid>
        </Grid>
    }

    return (
        <Paper elevation={1} style={{ margin: '16px' }}>
            <Grid container className='w3-padding-small'>
                <Grid container >
                    <Grid item md={12} className='w3-padding' style={{ display: 'flex' }}>
                        {!id && (<IconButton
                            onClick={() => navigate(`/client/${client_id}/meters`)}
                            color="inherit">
                            <ArrowBackIosIcon fontSize='small'/>
                        </IconButton>)}
                        <h6>{id ? 'Edit Meter' : 'Create New Meter'}</h6>
                    </Grid>
                    <Grid container item md={12} style={{ display: 'flex', flexDirection: 'row', }}>
                        <Grid container item md={12} className='w3-padding' style={{ alignContent: 'flex-start' }}>
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <TextField
                                    fullWidth
                                    label="Meter Name"
                                    required
                                    value={form.meterName}
                                    onChange={(e) => handleChange(e, 'meterName')}
                                />
                            </Grid>
                            {errors.meterName && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.meterName}</div>}
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <TextField
                                    fullWidth
                                    required
                                    label="Meter Id"
                                    value={form.meter_id}
                                    onChange={(e) => handleChange(e, 'meter_id')}
                                />
                            </Grid>
                            {errors.meter_id && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.meter_id}</div>}
                            <Grid container spacing={2}>
                                <Grid item md={6} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                        <Autocomplete
                                            fullWidth
                                            multiple
                                            options={[...meterTags, { value: 'custom'}]}
                                            isOptionEqualToValue={(option, value) => option.key === value.key}
                                            getOptionLabel={(option) => option.text}
                                            filterSelectedOptions
                                            value={selectedMeterTags}
                                            onChange={handleAutoCompleteChange}
                                            renderOption={renderOption}
                                            renderInput={(params) => (
                                            <TextField {...params} label="Meter Tags" placeholder="Meter Tags" />
                                            )}
                                        />
                                        <Dialog open={openAddTagDialog} onClose={handleCloseAddTagDialog}>
                                            <DialogTitle>Add Custom Meter Tag</DialogTitle>
                                            <DialogContent style={{ paddingTop: '6px' }}>
                                                <Grid container item md={12}>
                                                    <Grid item md={12} className='w3-margin-bottom' style={{ display: 'flex', flexDirection: 'row', }}>
                                                        <Grid item md={12}>
                                                            <TextField
                                                                required
                                                                label="Key"
                                                                value={customTagKey}
                                                                onChange={(e) => {handleCustomMtrTag("customTagKey"); setCustomTagKey(e.target.value.toLowerCase())}} 
                                                                fullWidth
                                                            />
                                                            {meterTagErrors.customTagKey && <div className='error-text'>{meterTagErrors.customTagKey}</div>}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item md={12} className='w3-margin-bottom' style={{ display: 'flex', flexDirection: 'row', }}>
                                                        <Grid item md={12}>
                                                            <TextField
                                                                required
                                                                label="Value"
                                                                value={customTagText}
                                                                onChange={(e) =>{handleCustomMtrTag("customTagText"); setCustomTagText(e.target.value)}}
                                                                fullWidth
                                                            />
                                                            {meterTagErrors.customTagText && <div  className='error-text'>{meterTagErrors.customTagText}</div>}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                
                                                <DialogActions style={{ paddingRight: '0px'}}>
                                                    <Button variant="outlined" onClick={handleCloseAddTagDialog}>Cancel</Button>
                                                    <Button disabled={tagDisableButton} variant="contained" onClick={handleAddCustomTag} color="primary">
                                                        Add
                                                    </Button>
                                                </DialogActions>
                                            </DialogContent>
                                        </Dialog>
                                </Grid>
                            </Grid>
                            
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <TextField
                                    fullWidth
                                    required
                                    label="Meter Address"
                                    value={form.meterAddress}
                                    onChange={(e) => handleChange(e, 'meterAddress')}
                                />
                            </Grid>
                            {errors.meterAddress && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.meterAddress}</div>}
                            
                            <Grid container spacing={2}>               
                                <Grid item md={6} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                    <Autocomplete
                                        fullWidth
                                        multiple
                                        options={projectData}
                                        value={selectedProjectIds}
                                        filterSelectedOptions
                                        isOptionEqualToValue={(option,value) => option.projname == value.projname}
                                        onChange={handleProjectAutoCompleteChange}
                                        getOptionLabel={(option) => `${option.projname} (PID:${option.pid})`}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <ListItemText primary={`${option.projname} (PID:${option.pid})`} secondary={option?.facilities?.facility_name} />
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                        {...params}
                                        label="Projects*"
                                        placeholder="Projects"
                                        />)}
                                    />
                                </Grid>
                            </Grid>
                            {errors.project && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.project}</div>}
                            {selectedProjectIds?.length > 0 && <Grid item md={12} sx={{ mt: 2, mb: 2 }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                                    <DatePicker
                                        label="Date of installation*"
                                        dateFormat="DD/MM/YYYY"
                                        format="DD/MM/YYYY"
                                        required
                                        slotProps={{ textField: { fullWidth: true, error: false, } }}
                                        name="installation_date"
                                        value={installation_date ? dayjs(installation_date) : null}
                                        onChange={(newValue) => {
                                            if(disableButton){
                                                setDisableButton(false)
                                            }
                                            setInstallationDate(newValue)
                                            setErrors({ ...errors, installation_date: null });
                                        }}
                                    />
                                </LocalizationProvider>
                                {errors.installation_date && <div className='error-text w3-margin-bottom'>{errors.installation_date}</div>}
                            </Grid>}
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>

                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="demo-multiple-name-label">Parameter Group *</InputLabel>
                                    <Select
                                        fullWidth
                                        label="Parameter Group"
                                        value={form.parameterGrp}
                                        onChange={(e) => handleChange(e, 'parameterGrp')}
                                    >
                                        {parameterGroup?.map(pGroup => <MenuItem key={pGroup.value} value={pGroup.value}>{pGroup.label}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {errors.parameterGrp && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.parameterGrp}</div>}
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="demo-multiple-name-label">Hardcoded Meter Name</InputLabel>
                                    <Select
                                        fullWidth
                                        value={form.hardcodedMtrName}
                                        label="Hardcoded Meter Name"
                                        onChange={(e) => handleChange(e, 'hardcodedMtrName')}
                                    >
                                        <MenuItem value={'Flow Rate'}>Flow Rate</MenuItem>
                                        <MenuItem value={'FL - Inlet Flow'}>FL - Inlet Flow</MenuItem>
                                        <MenuItem value={'FL - Total Flow'}>FL - Total Flow</MenuItem>
                                        <MenuItem value={'Pressure'}>Pressure</MenuItem>
                                        <MenuItem value={'Not Applicable'}>Not Applicable</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {errors.hardcodedMtrName && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.hardcodedMtrName}</div>}
                            <Grid container spacing={2}>
                                <Grid item md={6} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                    <TextField
                                        fullWidth
                                        label="Min"
                                        required={(form.parameterGrp === 'level')}
                                        value={form.min}
                                        onChange={(e) => handleChange(e, 'min')}
                                    />
                                </Grid>

                                <Grid item md={6} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                    <TextField
                                        fullWidth
                                        label="Min Remarks"
                                        required={(form.parameterGrp === 'level')}
                                        value={form.minRemarks}
                                        onChange={(e) => handleChange(e, 'minRemarks')}
                                    />

                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item md={6}>
                                    {errors.min && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.min}</div>}
                                </Grid>
                                <Grid item md={6}>
                                    {errors.minRemarks && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.minRemarks}</div>}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item md={6} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                    <TextField
                                        fullWidth
                                        label="Max"
                                        required={(form.parameterGrp === 'level')}
                                        value={form.max}
                                        onChange={(e) => handleChange(e, 'max')}
                                    />
                                </Grid>
                                <Grid item md={6} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>

                                    <TextField
                                        fullWidth
                                        required={(form.parameterGrp === 'level')}
                                        label="Max Remarks"
                                        value={form.maxRemarks}
                                        onChange={(e) => handleChange(e, 'maxRemarks')}
                                    />

                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item md={6}>
                                    {errors.max && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.max}</div>}
                                </Grid>
                                <Grid item md={6}>
                                    {errors.maxRemarks && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.maxRemarks}</div>}
                                </Grid>
                            </Grid>
                            {showWaterType && (<>
                                <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="demo-multiple-name-label">Water Type *</InputLabel>
                                        <Select
                                            fullWidth
                                            label="Water Type"
                                            value={form.waterType}
                                            onChange={(e) => handleChange(e, 'waterType')}
                                        >
                                            {waterTypeOptions.map(waterTypeOpt => <MenuItem value={waterTypeOpt.value}>{waterTypeOpt.label}</MenuItem>)}

                                        </Select>
                                    </FormControl>
                                </Grid>
                            {errors.waterType && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.waterType}</div>}
                        </> )}
                        <Grid container spacing={2} item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                            <Grid item md={6} sm={12}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="demo-multiple-name-label">Alert Priority *</InputLabel>
                                    <Select
                                        fullWidth
                                        label="Alert Priority"
                                        value={form.alertPriority}
                                        onChange={(e) => handleChange(e, 'alertPriority')}
                                    >
                                        <MenuItem value={'Moderate'}>Moderate</MenuItem>
                                        <MenuItem value={'High'}>High</MenuItem>
                                        <MenuItem value={'Critical'}>Critical</MenuItem>
                                    </Select>
                                </FormControl>
                                {errors.alertPriority && (
                                    <div className='error-text'>
                                        {errors.alertPriority}
                                    </div>
                                )}
                            </Grid>
                            <Grid item md={6} sm={12}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="demo-multiple-name-label">Meter Status *</InputLabel>
                                    <Select
                                        fullWidth
                                        label="Meter Status"
                                        value={form.status}
                                        onChange={(e) => handleChange(e, 'status')}
                                    >
                                        {meterStatus?.map(item => (
                                            <MenuItem key={`meter_status_${item?.id}`} value={item?.id}>{item?.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {errors.status && (
                                    <div className='error-text'>
                                        {errors.status}
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                            <Grid container item md={12} className='w3-margin-bottom'>
                                <Grid item md={9} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Grid>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox
                                                checked={form.hideChart}
                                                onChange={handleHideChart}
                                            />} label="Hide Chart" />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <TextField
                                    fullWidth
                                    label="Percentage Limit(%)"
                                    required={(form.parameterGrp === 'level')}
                                    value={form.percLimit}
                                    onChange={(e) => handleChange(e, 'percLimit')}
                                />
                            </Grid>
                            {errors.percLimit && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.percLimit}</div>}
                            {showTankFields && (
                                <>
                                    <Grid item md={12} style={{ display: 'flex', flexDirection: 'row' }} className='w3-margin-bottom'>
                                        <TextField
                                            fullWidth
                                            label="Tank Area"
                                            value={form.tankArea}
                                            required
                                            onChange={(e) => handleChange(e, 'tankArea')}
                                        />
                                    </Grid>
                                    {errors.tankArea && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.tankArea}</div>}

                                    <Grid item md={12} style={{ display: 'flex', flexDirection: 'row' }} className='w3-margin-bottom'>
                                        <TextField
                                            fullWidth
                                            label="Tank Height"
                                            value={form.tankHeight}
                                            required
                                            onChange={(e) => handleChange(e, 'tankHeight')}
                                        />
                                    </Grid>
                                    {errors.tankHeight && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.tankHeight}</div>}
                                </>

                            )}

                            {form.parameterGrp === 'level' && <Grid item md={12} className='w3-margin-bottom'>
                                <Grid container item md={12} className='w3-margin-bottom'>
                                    <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Grid>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox
                                                    checked={constantValueFlag}
                                                    onChange={() => { 
                                                        setConstantValueFlag(prevValue => !prevValue) 
                                                        setErrors({ ...errors, constantValue: null });
                                                        if (disableButton){
                                                            setDisableButton(false)
                                                        }
                                                    }}
                                                />} label="Constant" />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                    <TextField
                                        fullWidth
                                        label="Constant Value"
                                        required={constantValueFlag}
                                        disabled={!constantValueFlag}
                                        value={(form.constantValue !== undefined && form.constantValue !== null ) ? form.constantValue : ''}
                                        onChange={(e) => handleChange(e, 'constantValue')}
                                    />
                                </Grid>
                                {errors.constantValue && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.constantValue}</div>}
                            </Grid>}

                        </Grid>
                    </Grid>
                    <Grid item md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                        <Grid item md={6} style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '16px' }} className='w3-margin-bottom'>
                            {id && (
                                <Can do="manage" on="DeleteMeter">
                                    <Button
                                        variant="outlined"
                                        startIcon={<DeleteIcon />}
                                        color="error"
                                        onClick={() => triggerDelete()}
                                        >
                                        Delete
                                        </Button>
                                    </Can>
                                )}
                            </Grid>
                        <Grid item md={6} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '16px' }} className='w3-margin-bottom'>
                            <Button
                                variant="outlined"
                                onClick={() => meterQueryParams ? navigate(`/client/${client_id}/meters?${meterQueryParams?.toString()}`) : navigate(`/client/${client_id}/meters`)}

                            >
                                Cancel</Button>
                            <Can do="manage" on="AddMeter">
                                <Button
                                    disabled={disableButton}
                                    variant="contained"
                                    onClick={handleSubmit}
                                    style={{ marginLeft: '10px' }}
                                >
                                    Submit</Button>
                            </Can>
                        </Grid>
                    </Grid>
                    
                    {showConfirmDialog
                        ? (
                            <ConfirmDialog
                                handleCancel={() => {
                                    setSelectedItem({})
                                    setShowConfirmDialog(false)
                                }}
                                handleOk={handleDeleteMeter}
                                message={'Are you sure to delete this ?'}
                            />
                        ) : null}
                        {deleteLoader && <Loader open={deleteLoader} />}
                </Grid>
            </Grid>
        </Paper>
    )
}