import { useEffect } from 'react';
import {
    TextField, Select, MenuItem, Grid, FormGroup,
    FormControlLabel, Checkbox, Button, Paper, Tooltip, IconButton, FormControl, InputLabel
} from '@mui/material';

import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AlertDialog from '../Common/AlertDialog';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import ConfirmDialog from '../Common/ConfirmDialog'
import { createClient, updateClient, createMedia, getClientById, getSpocUser, deleteMedia, uploadMedia, deleteClient, setStoreData } from '../../Redux/actions';


import { Delete } from '@mui/icons-material';
import { red } from '@mui/material/colors';
import { makeid } from '../../utils';
import { Can } from '../../Context/Can'
import moment from 'moment';

import {isValidDate} from '../../utils/Common';


export default function CreateClient() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { client_id } = useParams();

    const intialForm = {
        name: '',
        address: '',
        primary_contact: '',
        domain: '',
        spoc: ''
    }
    const [form, setForm] = useState(intialForm);
    const [checked, setChecked] = useState({
        location: false,
        facility: false,
        project: false,
        meter: false,
    })
    const [onboard_date, setClientOnBoardDate] = useState(null)
    const error = {};
    const [errors, setErrors] = useState(error);
    const [showLoader, setShowLoader] = useState(true);
    const [alertMessage, setAlertMessage] = useState();
    const [msgType, setMsgType] = useState("success");
    const [showConfirmUploadDialog, setShowConfirmUploadDialog] = useState(false);
    const [uploadedImage, setUploadedImage] = useState();
    const [changed, setChanged] = useState(false);
    const [spocUser, setSpocUser] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [deletedItem, setDeletedItem] = useState(null)
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [disableButton, setDisableButton] = useState(false);

    const [clientFound, setClientFound] = useState(true);

    const spocData = ['SPOC 1', 'SPOC 2', 'SPOC 3'];
    const regionData = [
        'Tamilnadu',
        'Kerala',
        'Karnataka',
        'Andhra & Telangana',
        'Goa',
        'Gujarat',
        'Uttar Pradesh',
        'Jammu & Kashmir',
        'Rajasthan',
        'South Africa',
        'Qatar',
        'Others']

    const showAlert = (msg, type) => {
        setAlertMessage(msg);
        setMsgType(type);
    }

    const validateData = () => {
        const err = { ...errors };

        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;


        let hasErrors = false;
        if (!form.name || form.name.trim() === '') {
            hasErrors = true;
            err.name = 'This field is mandatory';
        }

        // if (!form.address || form.address.trim() === '') {
        //     hasErrors = true;
        //     err.address = 'This field is mandatory';
        // }

        // if (!form.primary_contact) {
        //     hasErrors = true;
        //     err.primary_contact = 'This field is mandatory';
        // }

        // if (form.primary_contact) {
        //     const emailValidation = emailRegex.test(form.primary_contact);
        //     if (!emailValidation) {
        //         hasErrors = true;
        //         err.primary_contact = 'Enter valid email id';
        //     }
        // }

        // if (!form.domain) {
        //     hasErrors = true;
        //     err.domain = 'This field is mandatory';
        // } else if (!/^(https?:\/\/)?(www\.)?[A-Za-z0-9-]{1,63}(\.[A-Za-z]{2,6}){1,2}(\/[^\s]*)?$/.test(form.domain)) {
        //     hasErrors = true;
        //     err.domain = 'Enter a valid URL';
        // }

        if (!onboard_date) {
            hasErrors = true;
            err.onboard_date = 'This field is mandatory';
        } else if (!isValidDate(onboard_date)) {
            hasErrors = true;
            err.onboard_date = 'Please enter a valid date in the format DD/MM/YYYY';
        }

        if (!form.region) {
            hasErrors = true;
            err.region = 'This field is mandatory';
        }

        if (!selectedImage) {
            hasErrors = true;
            err.uploadedImage = 'Please upload an image';
        }


        if (hasErrors) {
            setErrors(err);
            return false;
        }
        return true;
    };

    useEffect(() => {
        dispatch(getSpocUser()).then((res) => {
            const response = res?.data?.data;
            if (!response) {
                return;
            }
            setSpocUser(response)
        })
        if (client_id) {
            fetchClientData();
        }
    }, [client_id]);

    const fetchClientData = () => {
            dispatch(getClientById(client_id)).then((res) => {  
                const response = res?.data?.data;

                if (!response) {
                    setClientFound(false)
                    setShowLoader(false);
                    return;
                }

                const { name, address, domain, permission, primary_contact, region, onboard_date, spoc, logo_path, media } = response;

                if (onboard_date) {
                    setClientOnBoardDate(onboard_date);
                }
                const data = {
                    name,
                    address,
                    domain,
                    primary_contact,
                    region,
                    spoc,
                    onboard_date,
                    logo_path,
                    media
                }
                setSelectedImage(media);
                setUploadedImage(logo_path);
                let grantedPermission = null;
                if (permission) {
                    setChecked(permission)
                }
                const clientData = { ...data, ...grantedPermission }
                setForm(clientData);
                setShowLoader(false);
            })
    }

    const handleSubmit = () => {
        setDisableButton(true)
        const checkForm = validateData();
        const clientPermissions = {
            location: true,
            facility: true,
            project: true,
            meter: true,
        }

        if (checkForm) {
            const data = {
                name: form.name || null,
                address: form.address || null,
                domain: form.domain || null,
                permission: clientPermissions,
                primary_contact: form.primary_contact || null,
                region: form.region || null,
                onboard_date: onboard_date,
                spoc: form.spoc || null,
                client_id,
                entity_id: client_id,
                content_type: 'client'

            }
            const formData = new FormData();
            formData.append('data', JSON.stringify(data))
            if (deletedItem) {
                dispatch(deleteMedia(deletedItem)).then(res => { })
            }
            if (uploadedImage) {
                formData.append('imageFile', uploadedImage);
                formData.append('type', 'image');
                formData.append('size', uploadedImage?.size);
            }
            if (client_id) {
                dispatch(updateClient(formData, client_id)).then((res) => {
                    setDisableButton(false)
                    fetchClientData();
                    updateResponse(res, 'Client updated successfully');
                });
            } else {
                dispatch(createClient(formData)).then((res) => {
                    setDisableButton(false)
                    fetchClientData();
                    updateResponse(res, 'Client created successfully');
                });
            }
        }
    }

    const handleSelect = (e) => {
        const { name, value } = e.target;
        const data = Object.assign({}, form);
        data[name] = value;
        const err = Object.assign({}, errors);
        if (err[name]) {
            err[name] = null;
        }
        setErrors(err);
        setForm(data)
        if (disableButton) {
            setDisableButton(false)
        }

    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        const data = Object.assign({}, form);
        data[name] = value;
        const err = Object.assign({}, errors);
        if (err[name]) {
            err[name] = null;
        }
        setErrors(err);
        setForm(data);
        if (disableButton) {
            setDisableButton(false)
        }

    }

    const handleCheckbox = (e) => {
        const { name, checked } = e.target;
        setChecked((prevState) => ({
            ...prevState,
            [name]: checked
        }));

        const err = Object.assign({}, errors);
        if (err['checked']) {
            err['checked'] = null;
        }
        setErrors(err);
        if (disableButton) {
            setDisableButton(false)
        }
    }

    const handleFileChange = (event) => {
        let uploadImage;
        if (event.target.files.length) {
            const err = Object.assign({}, errors);
            if (err['uploadedImage']) {
                err['uploadedImage'] = null;
            }
            setErrors(err);
            uploadImage = event.target.files[0];
            setUploadedImage(uploadImage)
            setSelectedImage(URL.createObjectURL(uploadImage));
            event.target.value = ''
        }
        setDisableButton(false)

    }

    const handleConfirmUpload = () => {
        const formData = new FormData();
        if (uploadedImage) {
            formData.append('imageFile', uploadedImage);
            formData.append('type', 'image');
            formData.append('size', uploadedImage?.size);
        }
        setShowConfirmUploadDialog(false)
    }

    const handleDeleteMedia = (imageId) => {
        setSelectedImage(null)
        if (imageId.id) {
            setDeletedItem(imageId.id)
        }
    }

    const triggerDelete = (client) => {
        setShowConfirmDialog(true);
        setSelectedItem(client);
    }

    const deleteClientById = () => {
        dispatch(deleteClient(selectedItem)).then((resp) => {
            if (!resp) {
                return;
            } else {
                updateResponse(resp, resp?.data?.message);
                setShowConfirmDialog(false)
                // setSelectedItem(null);
            }
        })
    }

    const updateResponse = (resp, message) => {
        const res = resp && resp?.data;
        if (!res) {
            return;
        }
        let msg = res?.message;
        if (msg) {
            let type = "fail";
            if (res && res.success) {
                msg = message;
                type = "success";
            }
            dispatch(setStoreData('alertMessage', {
                msg, type,
                handleClose: () => handleCloseDialog(),
            }))
        }
    }


    const handleCloseDialog = () => {
        dispatch(setStoreData('alertMessage', null))
        if (client_id && !selectedItem) {
            navigate(`/client/${client_id}/settings/general`)
        } else {
            setSelectedItem(null);
            navigate('/client-listing');
        }
    }

    if (showLoader) {
        return <div className="w3-center">
            <CircularProgress />
        </div>
    }

    if (!clientFound) {
        return <Grid container justifyContent={"center"} style={{ marginTop: '160px' }}>
            <h5 className='w3-text-grey'>  No Data Found</h5>
        </Grid>
    }

    return (
        <div>
            <Paper elevation={1} style={{ margin: client_id ? '0px' : '16px' }}>
                <Grid container style={{ marginTop: client_id ? '5px' : '80px' }} spacing={4}>
                    <Grid container item md={6} style={{ paddingTop: '4px' }}>
                        <div className='w3-padding'>
                            <h6>{client_id ? 'Edit Client' : 'Create New Client'}</h6>
                        </div>
                    </Grid>
                    <Grid container item md={12} style={{ paddingTop: '0px' }}>
                        <Grid container item md={12} className='w3-padding'  >
                            <Grid container item md={12} className='w3-margin-bottom'  >
                                <TextField
                                    fullWidth
                                    label="Client Name"
                                    name="name"
                                    disabled
                                    required
                                    value={form.name}
                                    onChange={handleChange} />
                            </Grid>
                            {errors.name && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.name}</div>}
                            <Grid container item md={12} className='w3-margin-bottom' >
                                <TextField
                                    fullWidth
                                    required
                                    name="address"
                                    disabled
                                    value={form.address}
                                    label="Address"
                                    multiline
                                    rows={5}
                                    onChange={handleChange}
                                />
                            </Grid>
                            {errors.address && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.address}</div>}
                            <Grid item md={12} className='w3-margin-bottom' container>
                                <TextField
                                    name="primary_contact"
                                    required
                                    disabled
                                    value={form.primary_contact}
                                    fullWidth
                                    label="Primary Email"
                                    onChange={handleChange}
                                />
                            </Grid>
                            {errors.primary_contact && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.primary_contact}</div>}
                            <Grid item md={12} className='w3-margin-bottom' container>
                                <TextField
                                    name="domain"
                                    required
                                    disabled
                                    value={form.domain}
                                    fullWidth
                                    label="Domain"
                                    onChange={handleChange}
                                />
                            </Grid>
                            {errors.domain && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.domain}</div>}
                            <Grid item md={12} container>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="demo-multiple-name-label">SPOC</InputLabel>
                                    <Select
                                        value={form.spoc}
                                        name="spoc"
                                        label="SPOC"
                                        required
                                        fullWidth
                                        onChange={handleSelect}
                                    >
                                        {spocUser?.length === 0 ? <MenuItem value="" disabled>
                                            No Options Available
                                        </MenuItem> : (spocUser?.map(item => (
                                            <MenuItem key={item?.id} value={item?.id}>
                                                {item?.name}
                                            </MenuItem>
                                        )))}

                                    </Select>
                                    {errors.spoc && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.spoc}</div>}
                                </FormControl>

                            </Grid>

                            <Grid container item md={12} className='w3-margin-bottom'>
                                <label htmlFor="logo-file">
                                    <input
                                        style={{ display: 'none' }}
                                        required
                                        id="logo-file"
                                        name="logoFile"
                                        type="file"
                                        accept="image/jpeg, image/png, image/gif"
                                        onChange={handleFileChange}
                                    />

                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <Button
                                                className="w3-margin-top"
                                                color="primary"
                                                variant="outlined"
                                                size="medium"
                                                component="span">
                                                Upload Logo
                                            </Button>
                                        </div>
                                    </div>
                                    {errors.uploadedImage && <div className='error-text w3-margin-bottom'>{errors.uploadedImage}</div>}
                                </label>
                            </Grid>
                            <Grid container item md={12}>
                                {selectedImage && <div style={{ position: 'relative' }}>
                                    <Tooltip title="Delete" className='w3-right' style={{ position: 'absolute', top: '8px', right: '6px' }}>
                                        <IconButton className='tm-runcase-drawer-delete-icon' aria-label="delete" onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteMedia(selectedImage)
                                        }}>
                                            <Delete className="cursor-pointer"
                                                style={{ color: red[500] }}
                                            />

                                        </IconButton>
                                    </Tooltip>
                                    <img src={selectedImage?.path ? selectedImage.path : selectedImage} alt="Uploaded file" width="150px" style={{ padding: '5px' }} />

                                </div>}
                            </Grid>
                        </Grid>
                        <Grid container item md={12} spacing={2} className='w3-padding w3-margin-bottom' style={{ alignContent: 'flex-start' }}>
                            <Grid item md={6} container>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en'}>
                                    <DatePicker
                                        label="Client Onboard Date *"
                                        dateFormat="DD/MM/YYYY"
                                        format="DD/MM/YYYY"
                                        required
                                        slotProps={{ textField: { fullWidth: true, error: false, } }}
                                        name="onboard_date"
                                        value={dayjs(onboard_date)}
                                        onChange={(newValue) => {
                                            setClientOnBoardDate(newValue)
                                            if (disableButton) {
                                                setDisableButton(false)
                                            }
                                            setErrors({ ...errors, onboard_date: null });
                                        }}
                                        onKeyDown={(newValue) => {
                                            setErrors({ ...errors, onboard_date: null });
                                        }}
                                    />
                                </LocalizationProvider>
                                {errors.onboard_date && <div className='error-text w3-margin-bottom'>{errors.onboard_date}</div>}
                            </Grid>
                            <Grid item md={6} container>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="demo-multiple-name-label">Region *</InputLabel>
                                    <Select
                                        value={form.region}
                                        name="region"
                                        label="Region"
                                        required
                                        fullWidth
                                        onChange={handleSelect}
                                    >
                                        {regionData.map(item => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {errors.region && <div className='error-text w3-margin-bottom'>{errors.region}</div>}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item md={12} justifyContent={"flex-end"} spacing={2} style={{ paddingTop: '0px' }}>
                        <div className='w3-padding w3-margin-bottom' style={{ marginTop: '8px' }}>
                            <Can do="manage-xyz" on="ClientDelete-xxx">
                                {client_id ?
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        className="w3-margin-right"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            triggerDelete(client_id)
                                        }}
                                    >
                                        Delete
                                    </Button> : null
                                }
                            </Can>

                            <Button
                                variant="outlined"
                                className="w3-margin-right"
                                onClick={() => navigate(`/client/${client_id}/assets`)}
                            >
                                Cancel
                            </Button>
                            <Can do="manage" on="SaveClient">
                                <Button
                                    disabled={disableButton}
                                    color="primary"
                                    variant="contained"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </Button>
                            </Can>
                        </div>
                    </Grid>

                </Grid>
                {alertMessage ?
                    (
                        <AlertDialog
                            handleClose={handleCloseDialog}
                            message={alertMessage}
                            type={msgType}
                            handleDialogClose={() => setAlertMessage(null)}
                        />
                    )
                    : null
                }
                {showConfirmUploadDialog
                    ? (
                        <ConfirmDialog
                            handleCancel={() => {
                                setShowConfirmUploadDialog(false)
                            }}
                            handleOk={handleConfirmUpload}
                            message={'Are you sure to Upload Image?'}
                        />
                    ) : null}
                {showConfirmDialog
                    ? (
                        <ConfirmDialog
                            handleCancel={() => {
                                setSelectedItem({})
                                setShowConfirmDialog(false)
                            }}
                            handleOk={deleteClientById}
                            message={'Are you sure to delete this client?'}
                        />
                    ) : null}
            </Paper>
        </div>
    )
}