import { Box, Button, CircularProgress, Grid, IconButton, Paper, TableCell, TableContainer, TableRow, Tooltip, Table, TableHead, TableBody, Pagination } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import SearchBox from '../Common/SearchBox';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Edit } from '@mui/icons-material';
import { updateParams } from '../../utils';
import Filter from '../Common/Filter';
import { getAllComponents } from '../../Redux/actions';

export default function ComponentsList () {

    const [queryParams, setQueryParams] = useSearchParams();
    const existingParams = Object.fromEntries([...queryParams])

    let navigate = useNavigate()
    const goToRoutes = (routeApi) => {
        navigate(routeApi, { replace: true });
    }

    const location = useLocation();
    const dispatch = useDispatch();

    const [responseData, setResponseData] = useState([])
    const [showLoader, setShowLoader] = useState(true)
    const [hoveredRowId, setHoveredRowId] = useState(null);
    const [isSearchOrFilterApplied, setIsSearchOrFilterApplied] = useState(false);
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(null)
    const [currentPage, setCurrentPage] = useState(null);

    const tableColumns = ['Sl.No.', 'Name', 'ID', 'Category', 'Manufacturer', 'Specifications', '']

    useEffect(() => {
        if (existingParams?.page) {
            return
        }
        updateQueryParams({ page: 1 })
        fetchData();
    }, [queryParams]);

    useEffect(() => {
        setIsSearchOrFilterApplied(false);
        fetchData();
    }, [queryParams])

    const fetchData = () => {
        const params = {
            ...existingParams,
            title: existingParams?.search,
            page: isSearchOrFilterApplied ? 1 : existingParams?.page,
        }
        dispatch(getAllComponents(params)).then((res) => {
            if (res && res.status === 200) {
                setResponseData(res?.data?.components)
                setTotalPages(res?.data?.totalPages)
                setTotalCount(res?.data?.totalItems);
                setCurrentPage(res?.data?.currentPage)
                setShowLoader(false)
            }
        })
    }

    const updateQueryParams = (params) => {
        const searchParams = updateParams(existingParams, params)
        navigate({ search: searchParams.toString() }, { replace: true });
    }

    const handleSearch = (value) => {
        updateQueryParams({ search: value, page: 1 });
        setIsSearchOrFilterApplied(true);
    }

    const handlePageChange = (event, newPage) => {
        updateQueryParams({ page: newPage });
    };

    let tableContent = null;
    let componentList = []
    const itemsPerPage = 20;

    if ( showLoader ) {
        componentList = (
            <TableRow>
                <TableCell colSpan={6} align="center">
                    <CircularProgress />
                </TableCell>
            </TableRow>
        )
    } else if (!showLoader && responseData?.length <= 0) {
        componentList = (
            <TableRow>
                <TableCell colSpan={6} align="center">
                    <h5 className="w3-text-grey">No Data Found</h5>
                </TableCell>
            </TableRow>
        );
    } else {
        componentList = responseData.map((item, index) => {
            return (
                <TableRow
                    onMouseEnter={() => {
                        setHoveredRowId(item?.id);
                    }}
                    onMouseLeave={() => {
                        setHoveredRowId(null);
                    }}
                >
                    <TableCell>{(currentPage - 1) * itemsPerPage + index + 1}</TableCell>
                    <TableCell>{item?.name}</TableCell>
                    <TableCell>{item?.component_id}</TableCell>
                    <TableCell>{item?.category}</TableCell>
                    <TableCell>{item?.manufacturer}</TableCell>
                    <TableCell>{item?.specifications}</TableCell>
                    <TableCell>
                        <Box component="div" display="flex" justifyContent={"right"} >
                            <Tooltip title="Edit">
                                <IconButton aria-label="edit"
                                    style={{ opacity: hoveredRowId === item?.id ? 1 : 0, paddingLeft: '0px' }}
                                    onClick={() => { 
                                        navigate(`/components/edit/${item?.id}`)
                                        localStorage.setItem('componentsQueryParams', queryParams)
                                    }}
                                >
                                    <Edit className="cursor-pointer"
                                        style={{ color: 'black' }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </TableCell>
                </TableRow>
            )
        });
    }

    
    tableContent = componentList

    return (
        <div className='w3-padding-large'>
            <Grid container>
                <Grid item md={12}>
                    <Grid item container spacing={8} paddingY={2}>
                        <Grid item md={6}>
                            <Button
                                variant="contained"
                                onClick={() => { 
                                    if (localStorage.getItem('componentsQueryParams')) {
                                        localStorage.removeItem('componentsQueryParams');
                                    }
                                    navigate(`/components/add`) 
                                }}
                            >Add New Component</Button>
                        </Grid>
                        <Grid item md={6} container justifyContent="flex-end" direction="row">
                            <Grid item container md={12} direction="row">
                                <Grid item md={11} container justifyContent="flex-end">
                                    <h6 style={{ paddingRight: '5px', fontSize: '15px' }}>Search By: </h6>
                                    <SearchBox
                                        value={queryParams.get('search') || ''}
                                        placeholder='Component Name / ID'
                                        search={handleSearch}
                                    />
                                </Grid>
                                <Grid item container md={1} justifyContent="flex-end">
                                    <IconButton 
                                        onClick={() => setShowFilterDialog(true)} 
                                        title="Apply Filter">
                                        <FilterListIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Filter
                        filterArea="components"
                        showFilterDialog={showFilterDialog}
                        setShowFilterDialog={setShowFilterDialog}
                        updateQueryParams={updateQueryParams}
                        queryParams={queryParams}
                        setIsSearchOrFilterApplied={setIsSearchOrFilterApplied}
                    />
                    <Grid paddingX={0} paddingY={1}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table" style={{ tableLayout: 'auto' }}>
                                <TableHead>
                                    <TableRow>
                                    {tableColumns.map(tCol => (
                                        <TableCell style={{ fontSize: '14px', fontWeight: 'bold' }}>{tCol}</TableCell>
                                    ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableContent}
                                </TableBody>    
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                {( responseData && responseData.length > 0 ) && ( 
                    <Grid container item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '24px' }}>
                            <span style={{ fontFamily: '"Helvetica Neue"', fontSize: '13px', color: '#3d3d3d' }}>
                                {Math.min((parseInt(existingParams.page) - 1) * itemsPerPage + 1, totalCount)}-
                                {Math.min(parseInt(existingParams.page) * itemsPerPage, totalCount)} of {totalCount}
                            </span>
                        </Grid>
                        <Pagination
                            page={parseInt(existingParams?.page) || 1}
                            count={totalPages}
                            variant="outlined"
                            shape="rounded"
                            color="primary"
                            onChange={handlePageChange}
                        />
                    </Grid>
                )}
            </Grid>
        </div>
    )
}