import { Button, FormControl, Grid, IconButton, InputLabel, ListItemText, MenuItem, Paper, Select, TextField, InputAdornment, Autocomplete, createFilterOptions } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { DeleteIcon } from '../Common/TableUI';
import { useDispatch } from 'react-redux';
import { addNewClientAsset, deleteClientAsset, getAllComponents, getAllFacilities, getClientAssetById, getClientLocations, getMidsByFacilityId, setStoreData, updateClientAsset } from '../../Redux/actions';
import dayjs from 'dayjs';
import AlertDialog from '../Common/AlertDialog';
import moment from 'moment';
import ConfirmDialog from '../Common/ConfirmDialog';
import { isValidDate } from '../../utils/Common';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import MyLocationIcon from '@mui/icons-material/MyLocation';

export default function ManageClientAsset () {

    const location = useLocation();
    const locationState = location?.state;

    let { id: id, client_id: client_id } = useParams()
    let navigate = useNavigate()
    const dispatch = useDispatch();

    const assetCategories = [ 'Treatment', 'Distribution' ]

    let initialForm = {
        assetName: '',
        componentId: '',
        category: '',
        serialNumber: '',
        facility: '',
        particulars: '',
        coordinates: ''
    }

    const [form, setForm] = useState(initialForm);
    const [facilities, setFacilities] = useState(null)
    const [componentIds, setComponentIds] = useState(null)
    const [locationData, setLocationData] = useState(null);
    const [facilityName, setFacilityName] = useState('')
    const [purchaseDate, setPurchaseDate] = useState(null)
    const [installationDate, setInstallationDate] = useState(null)
    const [operationStartDate, setOperationStartDate] = useState(null)
    const [maintenanceDate, setMaintenanceDate] = useState(null)
    const [operationEndDate, setOperationEndDate] = useState(null)
    const [endOfLifeDate, setEndOfLifeDate] = useState(null)
    const [alertMessage, setAlertMessage] = useState();
    const [msgType, setMsgType] = useState("success");
    const [itemNotFound, setItemNotFound] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const error = {};
    const [errors, setErrors] = useState(error);
    const [disableButton, setDisableButton] = useState(false);
    const [meterData, setMeterData] = useState([])
    const [selectedMeterIds, setSelectedMeterIds] = useState([])

    const clientAssetQueryParams = localStorage.getItem("clientAssetQueryParams");

    useEffect(() => {
        dispatch(getClientLocations(client_id)).then((res) => {
            if (res && res.status === 200) {
                setLocationData(res.data.data)
            }
        });
        dispatch(getAllFacilities(client_id)).then((res) => {
            if (res && res.status === 200) {
                setFacilities(res.data.data)
            }
        });
        dispatch(getAllComponents()).then((res) => {
            if (res && res.status === 200) {
                const componentsWithMaintnceSchdle = res?.data?.allData?.filter(item => item?.compMaintenanceSchedules?.length > 0 )
                setComponentIds(componentsWithMaintnceSchdle)
            }
        });
    },[])

    useEffect(() => {
        if (id) {
            dispatch(getClientAssetById(client_id, id)).then((resp) => {
                const response = resp?.data?.assetData
                if (!response) {
                    setItemNotFound(true);
                    return;
                }

                const { name, component_id, category, serial_number, facility_id, purchase_date, installation_date,
                    operation_start_date, maintenance_date, operation_end_date, particulars, geo_proof_lat, geo_proof_lon,
                    end_of_life_date, assetMeter } = response
                
                if ( purchase_date || installation_date || operation_start_date || maintenance_date ) {
                    setPurchaseDate(moment(purchase_date).toISOString())
                    setInstallationDate(moment(installation_date).toISOString())
                    setOperationStartDate(moment(operation_start_date).toISOString())
                    setMaintenanceDate(moment(maintenance_date).toISOString())
                    setOperationEndDate(moment(operation_end_date).toISOString())
                    setEndOfLifeDate(moment(end_of_life_date).toISOString())
                }
                if ( facility_id ) {
                    fetchAssetMids(facility_id)
                }
                
                setSelectedMeterIds(assetMeter.map(item => item?.meter))
                const existData = {
                    assetName: name,
                    componentId: component_id,
                    category: category,
                    serialNumber: serial_number,
                    facility: facility_id,
                    particulars: particulars,
                    coordinates: (geo_proof_lat && geo_proof_lon) ? `${geo_proof_lat}, ${geo_proof_lon}` : ''

                }
                setForm(existData)
            })
        }
    }, []);

    const fetchAssetMids = (facility) => {
        dispatch(getMidsByFacilityId(client_id, facility)).then((resp) => {
            setMeterData(resp?.data?.midData)

        })
    }

    const handleChange = (e, fieldName) => {
        const { value } = e.target;
        const data = (prevState) => {
            let newData = {
                ...prevState,
                [fieldName]: value
            };
            return newData;
        };
        if ( fieldName === 'facility' ) {
            setSelectedMeterIds([])
            fetchAssetMids(value)
        }
        const err = Object.assign({}, errors);
        if (err[fieldName]) {
            err[fieldName] = null;
        }
        setErrors(err);
        setForm(data);
        if (disableButton) {
            setDisableButton(false)
        }
    }

    const handleMeterChange = (event,value) => { 
        setSelectedMeterIds(value);
        if (disableButton) {
            setDisableButton(false)
        }
        const err = Object.assign({}, errors);
        if (err['selectedMeterIds']) {
            err['selectedMeterIds'] = null;
        }
        setErrors(err)
    }

    const handleCoordinatesChange = (e) => {
        const value = e.target.value;
    
        const [latitude, longitude] = value.split(',').map((val) => val.trim());
    
        setForm((prevState) => ({
            ...prevState,
            latitude: latitude || '',
            longitude: longitude || '',
        }));
    };

    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setForm((prevForm) => ({
                        ...prevForm,
                        coordinates: `${latitude.toFixed(6)}, ${longitude.toFixed(6)}`
                    }));
                },
                (error) => {
                    console.error("Error fetching location: ", error);
                }
            );
        } else {
            console.error("Geolocation not supported by this browser.");
        }
    };

    const updateResponse = (resp, message) => {
        const res = resp && resp.data;
        let msg = res.message;
        let type = "fail";
        if (res && res.success) {
            msg = message;
            type = "success";
        }
        dispatch(setStoreData('alertMessage', {
            msg, type,
            handleClose: () => handleCloseDialog(),
        }))
    };

    const handleCloseDialog = () => {
        dispatch(setStoreData('alertMessage', null))
        // locationState?.status ? navigate(-1) : navigate(`/client/${client_id}/client-assets`);
        clientAssetQueryParams ? navigate(`/client/${client_id}/client-assets?${clientAssetQueryParams.toString()}`) : navigate(`/client/${client_id}/client-assets`)
    }

    const isValidCoordinates = (coordinates) => {
        const coordinatePattern = /^-?\d{1,2}(\.\d+)?,\s?-?\d{1,3}(\.\d+)?$/;
        if (!coordinatePattern.test(coordinates)) return false;
    
        const [latitude, longitude] = coordinates.split(',').map(coord => parseFloat(coord.trim()));
    
        return latitude >= -90 && latitude <= 90 && longitude >= -180 && longitude <= 180;
    };

    const validateData = () => {
        const err = { ...errors };
        let hasErrors = false;

        if (!form.assetName || form.assetName.trim() === '') {
            hasErrors = true;
            err.assetName = 'This field is mandatory';
        }

        if (!form.componentId || form.componentId.trim() === '') {
            hasErrors = true;
            err.componentId = 'This field is mandatory';
        }

        if (!form.category) {
            hasErrors = true;
            err.category = 'This field is mandatory';
        }

        if (!form.serialNumber || form.serialNumber.trim() === '') {
            hasErrors = true;
            err.serialNumber = 'This field is mandatory';
        }

        if (!form.facility) {
            hasErrors = true;
            err.facility = 'This field is mandatory';
        }

        if (!form.particulars || form.particulars.trim() === '') {
            hasErrors = true;
            err.particulars = 'This field is mandatory';
        }

        if ( !(selectedMeterIds.length > 0) ) {
            hasErrors = true;
            err.selectedMeterIds = 'This field is mandatory';
        }

        if (!purchaseDate) {
            hasErrors = true;
            err.purchaseDate = 'This field is mandatory';
        } else  if (!isValidDate(purchaseDate)) {
            hasErrors = true;
            err.purchaseDate = 'Please enter a valid date in the format DD/MM/YYYY';
        } else if ( purchaseDate > new Date() ) {
            hasErrors = true;
            err.purchaseDate = 'Future date cannot be selected';
        }

        if (!installationDate) {
            hasErrors = true;
            err.installationDate = 'This field is mandatory';
        } else if ( installationDate < purchaseDate ) {
            hasErrors = true;
            err.installationDate = 'Installation date cannot be less than Purchase date';
        } else  if (!isValidDate(installationDate)) {
            hasErrors = true;
            err.installationDate = 'Please enter a valid date in the format DD/MM/YYYY';
        } else if ( installationDate > new Date() ) {
            hasErrors = true;
            err.installationDate = 'Future date cannot be selected';
        }

        if (!operationStartDate) {
            hasErrors = true;
            err.operationStartDate = 'This field is mandatory';
        } else if ( operationStartDate < installationDate ) {
            hasErrors = true;
            err.operationStartDate = 'Operation start date cannot be less than Installation Date'
        } else  if (!isValidDate(operationStartDate)) {
            hasErrors = true;
            err.operationStartDate = 'Please enter a valid date in the format DD/MM/YYYY';
        } else if ( operationStartDate > new Date() ) {
            hasErrors = true;
            err.operationStartDate = 'Future date cannot be selected';
        }

        if ( operationEndDate && (operationEndDate < operationStartDate) ){
            hasErrors = true;
            err.operationEndDate = 'Operation start date cannot be less than operation end date'
        }  else if ( operationEndDate > new Date() ) {
            hasErrors = true;
            err.operationEndDate = 'Future date cannot be selected';
        }

        if ( endOfLifeDate && (endOfLifeDate < operationEndDate) ) {
            hasErrors = true;
            err.endOfLifeDate = 'Operation end date cannot be less than end of life date'
        } else if ( endOfLifeDate > new Date() ) {
            hasErrors = true;
            err.endOfLifeDate = 'Future date cannot be selected';
        }

        if ( form.coordinates) {
            if ( !isValidCoordinates(form.coordinates) ) {
                hasErrors = true;
                err.coordinates = 'Please enter valid coordinates'
            }
        }

        if (hasErrors) {
            setErrors(err);
            return false;
        }
        return true;
    }

    const handleSubmit = async() => {
        setDisableButton(true)
        const checkForm = validateData();
        if ( checkForm ) {
            let latitude = '';
            let longitude = '';
            if ( form?.coordinates ) {
                [latitude, longitude] = form?.coordinates.split(',').map(coord => coord.trim());
            }
            const data = {
                assetName: form.assetName,
                componentId: form.componentId,
                category: form.category,
                serialNumber: form.serialNumber,
                client_id,
                facility: form.facility,
                meterAddress: form.meterAddress,
                particulars: form.particulars,
                purchaseDate,
                installationDate,
                operationStartDate,
                maintenanceDate,
                operationEndDate,
                endOfLifeDate,
                latitude: latitude || '',
                longitude: longitude || '',
                selectedMeterIds
            }

            if (id) {
                dispatch(updateClientAsset(data, id)).then((resp) => {
                    setDisableButton(false)
                    if (!resp) {
                        return;
                    } else {
                        updateResponse(resp, resp?.data?.message);
                    }
                });
            } else {
                dispatch(addNewClientAsset(data)).then((resp) => {
                    setDisableButton(false)
                    if (!resp) {
                        return;
                    } else {
                        updateResponse(resp, resp?.data?.message);
                    }
                });
            }
        }
    };

    const triggerDelete = () => {
        setSelectedItem(id);
        setShowConfirmDialog(true);
    }

    const handleDeleteClientAsset = () => {
        if (selectedItem) {
            setShowConfirmDialog(false);
            dispatch(deleteClientAsset(client_id, selectedItem)).then((resp) => {
                updateResponse(resp, resp?.data?.message);
                setSelectedItem(null);
            })
        }
    }

    if (id && itemNotFound) {
        return <Grid container >
            <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center' }}>
                <h5 className='w3-text-grey'>
                    No Data Found
                </h5>
            </Grid>
        </Grid>
    }

    return (
        <Paper elevation={1} style={{ margin: '16px' }}>
            <Grid container className='w3-padding-small'>
                <Grid container >
                    <Grid item md={12} className='w3-padding' style={{ display: 'flex' }}>
                        {!id && (<IconButton
                            onClick={() => navigate(`/client/${client_id}/client-assets`)}
                            color="inherit">
                            <ArrowBackIosIcon fontSize='small'/>
                        </IconButton>)}
                        <h6>{id ? 'Edit Asset' : 'Add New Asset'}</h6>
                    </Grid>
                    <Grid container item md={12} style={{ display: 'flex', flexDirection: 'row', }}>
                        <Grid container item md={12} className='w3-padding' style={{ alignContent: 'flex-start' }}>
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <TextField
                                    fullWidth
                                    label="Asset Name"
                                    required
                                    value={form.assetName}
                                    onChange={(e) => handleChange(e, 'assetName')}
                                />
                            </Grid>
                            {errors.assetName && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.assetName}</div>}
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="demo-multiple-name-label">Component ID *</InputLabel>
                                    <Select
                                        fullWidth
                                        required
                                        value={form.componentId}
                                        name="componentId"
                                        label="Component ID"
                                        onChange={(e) => handleChange(e, 'componentId')}
                                        renderValue={(selected) => {
                                            const selectedItem = componentIds?.find(item => {
                                                return item.component_id === selected
                                            });
                                            return (
                                                <div>
                                                    {selectedItem?.component_id ? selectedItem.component_id : ''}
                                                </div>
                                            );
                                        }}
                                    >
                                        {componentIds?.map(item => <MenuItem key={item.id} value={item.component_id}><ListItemText primary={item?.component_id} /></MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {errors.componentId && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.componentId}</div>}
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <FormControl fullWidth required>
                                    <InputLabel>Category</InputLabel>
                                        <Select
                                        label="Category"
                                        value={form.category}
                                        onChange={(e) => handleChange(e, 'category')}
                                        >
                                            {assetCategories.map((category, index) => (
                                                <MenuItem key={index} value={category}>
                                                    {category}
                                                </MenuItem>
                                            ))} 
                                        </Select>
                                </FormControl>
                            </Grid>
                            {errors.category && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.category}</div>}
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <TextField
                                    fullWidth
                                    required
                                    label="Serial Number"
                                    value={form.serialNumber}
                                    onChange={(e) => handleChange(e, 'serialNumber')}
                                />
                            </Grid>
                            {errors.serialNumber && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.serialNumber}</div>}
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="demo-multiple-name-label">Facility *</InputLabel>
                                    <Select
                                        fullWidth
                                        required
                                        value={form.facility}
                                        name="facility"
                                        label="Facility"
                                        onChange={(e) => handleChange(e, 'facility')}
                                        renderValue={(selected) => {
                                            const selectedItem = facilities?.find(item => {
                                                return item.id === selected
                                            });
                                            return (
                                                <div>
                                                    {selectedItem?.facility_name ? selectedItem.facility_name : facilityName}
                                                </div>
                                            );
                                        }}
                                    >
                                        {facilities?.map(item => <MenuItem key={item.id} value={item.id}><ListItemText primary={item?.facility_name} /></MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {errors.facility && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.facility}</div>}
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }}>
                                <Autocomplete
                                    fullWidth
                                    multiple
                                    disabled={!form.facility}
                                    options={meterData}
                                    value={selectedMeterIds}
                                    filterSelectedOptions
                                    isOptionEqualToValue={(option,value) => option.mid === value.mid}
                                    onChange={handleMeterChange}
                                    getOptionLabel={(option) => `${option?.metername} - ${option?.meterid} (MID:${option?.mid})`}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <ListItemText primary={`${option?.metername} - ${option?.meterid} (MID:${option?.mid})`} />
                                        </li>
                                    )}
                                    // onChange={(newValue) => {
                                    //     if (disableButton) {
                                    //         setDisableButton(false)
                                    //     }
                                    //     setSelectedMeterIds(newValue);
                                    //     setErrors({ ...errors, selectedMeterIds: null });
                                    // }}
                                    renderInput={(params) => (
                                        <TextField
                                    {...params}
                                    label='Meters *'
                                    placeholder="Meters"
                                    />)}
                                />
                            </Grid>
                            {errors.selectedMeterIds && <div className='error-text w3-margin-bottom'>{errors.selectedMeterIds}</div>}
                            <Grid container spacing={2}>
                                <Grid item md={4} sx={{ mt: 2, mb: 2 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                        <DatePicker
                                            label="Date of Purchase*"
                                            format="DD/MM/YYYY"
                                            required
                                            slotProps={{ textField: { fullWidth: true, error: false, } }}
                                            name="purchaseDate"
                                            value={dayjs(purchaseDate)}
                                            onChange={(newValue) => {
                                                if (disableButton) {
                                                    setDisableButton(false)
                                                }
                                                setPurchaseDate(newValue)
                                                setErrors({ ...errors, purchaseDate: null });
                                            }}
                                            maxDate={dayjs()}
                                        />
                                    </LocalizationProvider>
                                    {errors.purchaseDate && <div className='error-text'>{errors.purchaseDate}</div>}
                                </Grid>
                                <Grid item md={4} sx={{ mt: 2, mb: 2 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                        <DatePicker
                                            label="Date of Installation*"
                                            format="DD/MM/YYYY"
                                            disabled={!purchaseDate}
                                            required
                                            minDate={dayjs(purchaseDate)}
                                            slotProps={{ textField: { fullWidth: true, error: false, } }}
                                            name="installationDate"
                                            value={dayjs(installationDate)}
                                            onChange={(newValue) => {
                                                if (disableButton) {
                                                    setDisableButton(false)
                                                }
                                                setInstallationDate(newValue)
                                                setErrors({ ...errors, installationDate: null });
                                            }}
                                            maxDate={dayjs()}
                                        />
                                    </LocalizationProvider>
                                    {errors.installationDate && <div className='error-text w3-margin-bottom'>{errors.installationDate}</div>}
                                </Grid>
                                <Grid item md={4} sx={{ mt: 2, mb: 2 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                        <DatePicker
                                            label="Operation Start Date*"
                                            format="DD/MM/YYYY"
                                            required
                                            disabled={!installationDate}
                                            minDate={dayjs(installationDate)}
                                            slotProps={{ textField: { fullWidth: true, error: false, } }}
                                            name="operationStartDate"
                                            value={dayjs(operationStartDate)}
                                            onChange={(newValue) => {
                                                if (disableButton) {
                                                    setDisableButton(false)
                                                }
                                                setOperationStartDate(newValue)
                                                setErrors({ ...errors, operationStartDate: null });
                                            }}
                                            maxDate={dayjs()}
                                        />
                                    </LocalizationProvider>
                                    {errors.operationStartDate && <div className='error-text w3-margin-bottom'>{errors.operationStartDate}</div>}
                                </Grid>
                                {/* <Grid item md={3} sx={{ mt: 2, mb: 2 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                        <DatePicker
                                            label="Maintenance Date*"
                                            format="DD/MM/YYYY"
                                            required
                                            disabled={!operationStartDate}
                                            minDate={dayjs(operationStartDate)}
                                            slotProps={{ textField: { fullWidth: true, error: false, } }}
                                            name="maintenanceDate"
                                            value={dayjs(maintenanceDate)}
                                            onChange={(newValue) => {
                                                if (disableButton) {
                                                    setDisableButton(false)
                                                }
                                                setMaintenanceDate(newValue)
                                                setErrors({ ...errors, maintenanceDate: null });
                                            }}
                                        />
                                    </LocalizationProvider>
                                    {errors.maintenanceDate && <div className='error-text w3-margin-bottom'>{errors.maintenanceDate}</div>}
                                </Grid> */}
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item md={4} sx={{ mt: 2, mb: 2 }} style={{ marginTop: '0px'}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                        <DatePicker
                                            label="Operation End Date"
                                            format="DD/MM/YYYY"
                                            // required
                                            disabled={!operationStartDate}
                                            minDate={dayjs(operationStartDate)}
                                            slotProps={{ textField: { fullWidth: true, error: false, } }}
                                            name="operationEndDate"
                                            value={dayjs(operationEndDate)}
                                            onChange={(newValue) => {
                                                if (disableButton) {
                                                    setDisableButton(false)
                                                }
                                                setOperationEndDate(newValue)
                                                setErrors({ ...errors, operationEndDate: null });
                                            }}
                                            maxDate={dayjs()}
                                        />
                                    </LocalizationProvider>
                                    {errors.operationEndDate && <div className='error-text'>{errors.operationEndDate}</div>}
                                </Grid>
                                <Grid item md={4} sx={{ mt: 2, mb: 2 }} style={{ marginTop: '0px'}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                        <DatePicker
                                            label="End Of Life Date"
                                            format="DD/MM/YYYY"
                                            // required
                                            disabled={!operationEndDate}
                                            minDate={dayjs(operationEndDate)}
                                            slotProps={{ textField: { fullWidth: true, error: false, } }}
                                            name="endOfLifeDate"
                                            value={dayjs(endOfLifeDate)}
                                            onChange={(newValue) => {
                                                if (disableButton) {
                                                    setDisableButton(false)
                                                }
                                                setEndOfLifeDate(newValue)
                                                setErrors({ ...errors, endOfLifeDate: null });
                                            }}
                                            maxDate={dayjs()}
                                        />
                                    </LocalizationProvider>
                                     {errors.endOfLifeDate && <div className='error-text'>{errors.endOfLifeDate}</div>}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item md={12} sx={{ mt: 2, mb: 2 }} style={{ marginTop: '0px'}}>
                                    <TextField
                                        // required
                                        fullWidth
                                        value={form.coordinates}
                                        label="Geo Tagging"
                                        placeholder='12.9716, 77.5946'
                                        name="coordinates"
                                        onChange={(e) => handleChange(e, 'coordinates')} 
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={getCurrentLocation}>
                                                        <MyLocationIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        />
                                </Grid>
                            </Grid>
                            {errors.coordinates && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.coordinates}</div>}
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <TextField
                                    fullWidth
                                    required
                                    multiline
                                    rows={4}
                                    label="Particulars"
                                    value={form.particulars}
                                    onChange={(e) => handleChange(e, 'particulars')}
                                />
                            </Grid>
                            {errors.particulars && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.particulars}</div>}
                        </Grid>
                    </Grid>
                    <Grid item md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                        <Grid item md={6} style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '16px' }} className='w3-margin-bottom'>
                            {id && (
                                    <Button
                                        variant="outlined"
                                        startIcon={<DeleteIcon />}
                                        color="error"
                                        onClick={() => triggerDelete()}
                                        >
                                        Delete
                                        </Button>
                                )}
                            </Grid>
                        <Grid item md={6} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '16px' }} className='w3-margin-bottom'>
                            <Button
                                variant="outlined"
                                onClick={() => clientAssetQueryParams ? navigate(`/client/${client_id}/client-assets?${clientAssetQueryParams?.toString()}`) : navigate(`/client/${client_id}/client-assets`)}
                            >
                                Cancel</Button>
                                <Button
                                    // disabled={disableButton}
                                    variant="contained"
                                    onClick={handleSubmit}
                                    style={{ marginLeft: '10px' }}
                                    disabled={disableButton}
                                >
                                    Submit</Button>
                        </Grid>
                    </Grid>
                </Grid>
                {alertMessage ?
                    (
                        <AlertDialog
                            handleClose={handleCloseDialog}
                            message={alertMessage}
                            type={msgType}
                            handleDialogClose={() => setAlertMessage(null)}
                        />
                    )
                    : null
                }
                {showConfirmDialog
                        ? (
                            <ConfirmDialog
                                handleCancel={() => {
                                    setSelectedItem({})
                                    setShowConfirmDialog(false)
                                }}
                                handleOk={handleDeleteClientAsset}
                                message={'Are you sure to delete this ?'}
                            />
                        ) : null}
            </Grid>
        </Paper>
    )
}