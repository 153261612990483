import { CircularProgress, Grid, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import Paper from '@mui/material/Paper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { deleteGeneratedReport, viewGeneratedReport } from "../../Redux/actions";
import moment from 'moment';
import { isGreenUser, month } from "../../utils/Common";
import axios from 'axios';
import { Delete, Edit } from "@mui/icons-material";
import { red } from "@mui/material/colors";
import ReportsContent from "./ReportsContent";
import { updateParams } from "../../utils";
import ConfirmDialog from "../Common/ConfirmDialog";

export default function EditedConsolidateReports({
    setDisableDownload, pid, reportType, archiveReportHtmlContent, setArchiveReportHtmlContent, 
    onReportSaved, projectName, handleClickGenerate, setPermissionNotFound
}) {
    const { client_id, id } = useParams();
    const state = useSelector(st => st);
    const { currentUser: { uniqueId } } = state
    const project_id = id ? id : pid
    const dispatch = useDispatch();
    const [reportData, setReportData] = useState([])
    const [showLoader, setShowLoader] = useState(true)
    const [hoveredRowId, setHoveredRowId] = useState(null);
    const [contentEditable, setContentEditable] = useState(false);
    const [currentReportId, setCurrentReportId] = useState(null);
    const [currentProjectId, setCurrentProjectId] = useState(null);

    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(null)
    const [currentPage, setCurrentPage] = useState(null)
    const [queryParams, setQueryParams] = useSearchParams();
    const existingParams = Object.fromEntries([...queryParams])
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const tableColumns = ['SI No', 'Project', 'From Date', 'To Date', 'Month', '']

    let navigate = useNavigate();

    const fetchData = () => {
        if (!existingParams?.page || project_id?.length === 0) {
            return
        }
        const params = {
            page: existingParams?.page
        }
        dispatch(viewGeneratedReport(client_id, project_id, params)).then(resp => {
            setShowLoader(false)
            if (!resp.data.success && !resp.data.permissionFound ) {
                setPermissionNotFound(true);
                return
            }
            setDisableDownload(true);
            if (!resp.data.data) {
                setReportData([])
                return
            }
            const respData = resp.data
            setReportData(respData?.data);
            setTotalPages(respData?.totalPages)
            setTotalCount(respData?.totalItems);
            setCurrentPage(respData?.currentPage)
        })
    }

    useEffect(() => {
        setReportData([]);
        if (reportType !== 'Saved Reports') {
            return
        }
    }, [reportType])

    useEffect(() => {
        setShowLoader(true)
        if (project_id) {
            fetchData()
        }
    }, [project_id, queryParams, handleClickGenerate])

    useEffect(() => {
        if (!existingParams.page) {
            updateQueryParams({ page: 1 });
        }
    }, [queryParams]);

    const updateQueryParams = (params) => {
        const searchParams = updateParams(existingParams, params)
        navigate({ search: searchParams.toString() }, { replace: true });
    }

    const handleReportView = (item, editFlg = false) => {
        const params = {
            'pid': item.pid,
            'client_id': client_id,
            'report_id': item.id,
            'content_editable': editFlg
        }
        axios.get(`/papi/v1/show-report-table/`, { params })
            .then((response) => {
                if (response.data) {
                    setArchiveReportHtmlContent(response.data);
                } else {
                    setArchiveReportHtmlContent('<div style="margin:12px 0 20px 0; font-size: 16px">Not available in GR</div>')
                }
            })
            .catch((error) => {
                setArchiveReportHtmlContent('<div>Not available error</div>')
            });
    }

    const handleDeleteReport = () => {
        dispatch(deleteGeneratedReport(client_id, selectedItem)).then(resp => {
            setShowConfirmDialog(false);
            if (!resp?.data?.success) {
                return
            }
            fetchData()
        })
    }

    const handlePageChange = (event, newPage) => {
        updateQueryParams({ page: newPage });
    };

    const itemsPerPage = 20;
    let reportList = [];

    if (showLoader) {
        reportList = (
            <TableRow>
                <TableCell colSpan={6} align="center">
                    <CircularProgress />
                </TableCell>
            </TableRow>
        )
    }
    else if (reportData.length === 0) {
        reportList = (
            <TableRow>
                <TableCell colSpan={6} align="center">
                    <h5 className="w3-text-grey">No Data Found</h5>
                </TableCell>
            </TableRow>
        );
    } else {
        reportList = (reportData?.map((item, index) => {
            const reportDate = new Date(item?.to_date);
            const reportMonth = month[reportDate.getMonth()];
            return <TableRow
                key={index}
                onMouseEnter={() => {
                    setHoveredRowId(item?.id);
                }}
                onMouseLeave={() => {
                    setHoveredRowId(null);
                }}
            >
                <TableCell align="left">{(currentPage - 1) * itemsPerPage + index + 1}</TableCell>
                <TableCell>{item?.project_name?.replace("WA-", "").trim()}</TableCell>
                <TableCell>{moment(item?.from_date).format('YYYY-MM-DD')}</TableCell>
                <TableCell>{moment(item?.to_date).format('YYYY-MM-DD')}</TableCell>
                <TableCell>{reportMonth}</TableCell>
                <TableCell style={{ display: 'flex' }}>
                    <Tooltip title="View">
                        <IconButton aria-label="view"
                            style={{ opacity: hoveredRowId === item?.id ? 1 : 0, paddingLeft: '0px' }}
                            onClick={() => {
                                setContentEditable(false);
                                handleReportView(item)
                                setDisableDownload(false);
                            }}
                        >
                            <VisibilityIcon className="cursor-pointer" />
                        </IconButton>
                    </Tooltip>
                    {isGreenUser(uniqueId) && (
                        <>
                            <Tooltip title="Edit">
                                <IconButton aria-label="edit"
                                    style={{ opacity: hoveredRowId === item?.id ? 1 : 0, paddingLeft: '0px' }}
                                    onClick={() => {
                                        setContentEditable(true);
                                        setCurrentReportId(item.id);
                                        setCurrentProjectId(item.pid)
                                        setDisableDownload(false);
                                        handleReportView(item, true)
                                    }}
                                >
                                    <Edit className="cursor-pointer" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete" className='w3-right'>
                                <IconButton className='tm-runcase-drawer-delete-icon'
                                    style={{ opacity: hoveredRowId === item?.id ? 1 : 0, paddingLeft: '0px' }}
                                    aria-label="delete" onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedItem(item?.id);
                                        setShowConfirmDialog(true);
                                    }}>
                                    <Delete className="cursor-pointer"
                                        style={{ color: red[500] }}
                                    />

                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                </TableCell>
            </TableRow>
        }))
    }

    return (
        <div style={{ marginTop: archiveReportHtmlContent ? 12 : 30 }}>
            <Grid container>
                <Grid item md={12}>
                    <Grid paddingX={0} paddingY={1}>
                        {archiveReportHtmlContent ? (
                            <>
                                <span className="hide-in-print back-arrow-btn"
                                    onClick={() => {
                                        setArchiveReportHtmlContent("")
                                        setDisableDownload(true)
                                    }}
                                >
                                    <ArrowBackIcon />
                                </span>
                                <ReportsContent
                                    htmlDataContent={archiveReportHtmlContent}
                                    setHtmlDataContent={setArchiveReportHtmlContent}
                                    editReport={contentEditable}
                                    client_id={client_id}
                                    projectId={currentProjectId || id}
                                    reportType={reportType}
                                    onReportSaved={onReportSaved}
                                    projectName={projectName}
                                    currentReportId={currentReportId}
                                />
                            </>) : (
                            <>
                                <TableContainer component={Paper} className="hide-in-print">
                                    <Table aria-label="simple table" style={{ tableLayout: 'auto' }}>
                                        <TableHead>
                                            <TableRow>
                                                {tableColumns.map(columnHeader => (
                                                    <TableCell key={columnHeader} style={{ fontSize: '14px', fontWeight: 'bold' }}>{columnHeader}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportList}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid container item xs={12} style={{ marginBottom: '30px', marginTop: '12px' }}>
                                    <Grid item xs={12} display="flex" justifyContent="end" style={{ marginBottom: '10px' }}>
                                        <span style={{ fontFamily: '"Helvetica Neue"', fontSize: '13px', color: '#3d3d3d' }}>
                                            {Math.min((parseInt(existingParams.page ? existingParams.page : 1) - 1) * itemsPerPage + 1, totalCount)}-
                                            {Math.min(parseInt(existingParams.page ? existingParams.page : 1) * itemsPerPage, totalCount)} of {totalCount}
                                        </span>
                                    </Grid>
                                    <Grid item xs={12} display="flex" justifyContent="center">
                                        <Pagination className='pagination'
                                            page={parseInt(existingParams?.page) || 1}
                                            count={totalPages}
                                            variant="outlined"
                                            shape="rounded"
                                            color="primary"
                                            onChange={handlePageChange}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>

            {showConfirmDialog
                ? (
                    <ConfirmDialog
                        handleCancel={() => {
                            setSelectedItem({})
                            setShowConfirmDialog(false)
                        }}
                        handleOk={handleDeleteReport}
                        message={'Are you sure to delete this report?'}
                    />
                ) : null}
        </div>
    )
}